import styled from 'styled-components';
import { Grid } from '@puregym/ui';

import { BlogPostLinkBlock } from './blogPostLinkBlock';

const StyledGrid = styled(Grid)`
  ${({ theme }) => theme.mediaQueries.lgMax} {
    gap: ${({ theme }) => theme.spacing.baseSpacing};
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    grid-template-columns: ${({ minItemWidth, theme }) =>
      `repeat(auto-fit, minmax(${minItemWidth}, calc(25% - ${
        theme.BASE_SPACING * 1.5
      }px)))`};
  }
`;

const BlogPostLinkListBlock = ({ data: { content } }) => (
  <StyledGrid minItemWidth="240px">
    {content.blogPosts.map((data) => (
      <BlogPostLinkBlock data={data} key={data.key} />
    ))}
  </StyledGrid>
);

export { BlogPostLinkListBlock };
