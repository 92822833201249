import { Helmet } from 'react-helmet';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
} from '@puregym/ui';

import { RichText } from '~/ui';

const AccordionItemBlock = ({
  data: {
    content: { text, title },
  },
  uuid,
}) => (
  <AccordionItem uuid={uuid}>
    <AccordionItemHeading>{title}</AccordionItemHeading>
    <AccordionItemPanel>
      <RichText data={text} />
    </AccordionItemPanel>
  </AccordionItem>
);

const getStructuredData = (questions) => {
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: questions.map((question) => ({
      '@type': 'Question',
      name: question.content?.title,
      acceptedAnswer: {
        '@type': 'Answer',
        text: question.content.text?.text,
      },
    })),
  });
};

const AccordionBlock = ({ data: { content, settings } }) => {
  const preExpandedItems = content.blocks.reduce((result, item) => {
    if (item.settings?.preExpanded === true) {
      result.push(item.key);
    }
    return result;
  }, []);

  return (
    <>
      {settings?.isFaqList && (
        <Helmet>
          <script type="application/ld+json">
            {getStructuredData(content.blocks)}
          </script>
        </Helmet>
      )}
      <Accordion
        allowMultipleExpanded={true}
        allowZeroExpanded={true}
        preExpanded={preExpandedItems}
      >
        {content.blocks.map((item) => (
          <AccordionItemBlock data={item} key={item.key} uuid={item.key} />
        ))}
      </Accordion>
    </>
  );
};

export { AccordionBlock };
