import { Carousel } from '@puregym/ui';

import { usePageState } from '~/context';
import { UI_BLOCK_TYPES } from './constants';
import { renderUIBlock } from './block';

const CarouselBlock = ({ data: { content, settings } }) => {
  const {
    draggable,
    loop,
    visibleSlides,
    visibleSlidesDesktop,
    visibleSlidesTablet,
  } = settings;
  const { global, labels } = usePageState();
  const isRtl = global.languages.currentCulture?.rtl;

  return (
    <Carousel
      direction={isRtl ? 'rtl' : 'ltr'}
      draggable={draggable}
      labels={{ previous: labels.core.previous, next: labels.core.next }}
      loop={loop}
      visibleSlides={visibleSlides ? visibleSlides : 1}
      visibleSlidesDesktop={visibleSlidesDesktop ? visibleSlidesDesktop : 3}
      visibleSlidesTablet={visibleSlidesTablet ? visibleSlidesTablet : 3}
    >
      {content.blocks?.map((data) => {
        if (Object.values(UI_BLOCK_TYPES).includes(data.contentType)) {
          return renderUIBlock(data);
        }
      })}
    </Carousel>
  );
};

export { CarouselBlock };
