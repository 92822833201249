import { createContext, useContext, useReducer } from 'react';
import { arrayOf, node, oneOfType } from 'prop-types';

import { pageReducer } from './reducer';

/**
 * Context for the rendered page. State for blocks + global data
 *
 * Follows patterns outlined here:
 * @see https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */
const PageStateContext = createContext();
const PageDispatchContext = createContext();

const usePageState = () => {
  const context = useContext(PageStateContext);
  if (context === undefined) {
    throw new Error('usePageState must be used within a pageProvider');
  }
  return context;
};

const usePageDispatch = () => {
  const context = useContext(PageDispatchContext);
  if (context === undefined) {
    throw new Error('usePageDispatch must be used within a pageProvider');
  }
  return context;
};

const usePage = () => [usePageState(), usePageDispatch()];

const PageProvider = ({ children, initialState }) => {
  const [state, dispatch] = useReducer(pageReducer, initialState);

  return (
    <PageStateContext.Provider value={state}>
      <PageDispatchContext.Provider value={dispatch}>
        {children}
      </PageDispatchContext.Provider>
    </PageStateContext.Provider>
  );
};

const pageProviderProps = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};

PageProvider.propTypes = pageProviderProps;

export { PageProvider, usePage, usePageState, usePageDispatch };
