import styled from 'styled-components';
import { PageFooter } from '@puregym/ui';

import { usePageState } from '~/context';
import { FooterNav } from './footerNav';
import { Identify } from './serverInfo';
import { SimpleLangSwitcher } from './simpleLangSwitcher';

const Colophon = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSizes.xsmall};

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const trademarkBrands = ['PFUS'];

const SiteFooter = () => {
  const { global } = usePageState();
  const isLanguageSelectorVisible = global.layout?.languageSelectorVisible;
  const brandId = global.brand?.id;

  /** By default we prepend a copyright icon; in the US we append TM instead */
  const legalDisplayName = trademarkBrands.includes(brandId)
    ? `${global.brand?.legalName} \u2122`
    : `\u00A9 ${global.brand?.legalName}`;

  return (
    <PageFooter>
      {isLanguageSelectorVisible && <SimpleLangSwitcher />}
      <FooterNav navData={global.footerNavigation} />
      {brandId ? (
        <Colophon>
          <span>{legalDisplayName}</span>
          <span>{global.brand?.legalNotice}</span>
        </Colophon>
      ) : null}
      <Identify serverSupportData={global.serverSupport} />
    </PageFooter>
  );
};

export { SiteFooter };
