export const GET_PAGE_DATA = 'GET_PAGE_DATA';
export const GET_PAGE_DATA_ERROR = 'GET_PAGE_DATA_ERROR';
export const GET_PAGE_DATA_SUCCESS = 'GET_PAGE_DATA_SUCCESS';

export const GET_GLOBAL_DATA = 'GET_GLOBAL_DATA';
export const GET_GLOBAL_DATA_ERROR = 'GET_GLOBAL_DATA_ERROR';
export const GET_GLOBAL_DATA_SUCCESS = 'GET_GLOBAL_DATA_SUCCESS';

export const GET_LABELS = 'GET_LABELS';
export const GET_LABELS_ERROR = 'GET_LABELS_ERROR';
export const GET_LABELS_SUCCESS = 'GET_LABELS_SUCCESS';
