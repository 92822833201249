import { Nav as NavMenu, IconMenu, IconCookieBite } from '@puregym/ui';
import { renewCookieConsent } from '@puregym/cookieconsent';

import { usePageState } from '~/context';

const Nav = () => {
  const { global, labels } = usePageState();
  const primaryNavigation = global.primaryNavigation || [];
  const trackingPermissionRequired = global.trackingPermissionRequired;

  const mapNavData = (navItem) => {
    return {
      ...navItem,
      children: navItem.navigationItems.length
        ? navItem.navigationItems.map(mapNavData)
        : null,
    };
  };

  const primaryNavData = primaryNavigation.map(mapNavData);

  const cookieConsentMenuItem = {
    name: labels.core.cookieConsentModify,
    icon: <IconCookieBite aria-hidden={true} />,
    divider: true,
    onClick: () =>
      renewCookieConsent(global.languages.currentCulture?.languageCode),
  };

  if (trackingPermissionRequired) {
    primaryNavData.push(cookieConsentMenuItem);
  }

  return (
    <NavMenu
      variant="global"
      data={primaryNavData}
      width="320px"
      labels={{
        back: labels.core.menuBackText,
        close: labels.core.closeMenuButton,
        title: labels.core.menuTitle,
      }}
      trigger={
        <>
          <IconMenu title={labels.core.menuButton} />
          <span data-hidemobile aria-hidden={true}>
            {labels.core.menuButton}
          </span>
        </>
      }
    />
  );
};

export { Nav };
