import styled from 'styled-components';

const ClassInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-block-end: ${({ theme }) => theme.spacing.baseSpacing};
`;

const ClassInfoList = styled.dl`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: ${({ theme }) => `0 ${theme.spacing.doubleSpacing}`};

  &:first-child {
    border-inline-end: ${({ theme }) =>
      `1px solid ${theme.colors.accents.muted}`};
  }

  dt,
  dd {
    margin: 0;
    padding: 0;
    line-height: 1.1;
  }

  dd {
    text-transform: uppercase;
    font-weight: bold;
  }
`;

const ClassInfoBlock = ({ data: { content } }) => {
  const { duration, durationLabel, fitnessLevel, fitnessLevelLabel } = content;

  return (
    <ClassInfo>
      <ClassInfoList>
        <dt>{fitnessLevelLabel}</dt>
        <dd>{fitnessLevel}</dd>
      </ClassInfoList>
      <ClassInfoList>
        <dt>{durationLabel}</dt>
        <dd>{duration}</dd>
      </ClassInfoList>
    </ClassInfo>
  );
};

export { ClassInfoBlock };
