import { usePageState } from '~/context';
import { Blocks } from '~/blocks';

const Home = () => {
  const { page } = usePageState();

  return <Blocks data={page.blocks} />;
};

export { Home };
