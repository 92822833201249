import styled from 'styled-components';
import { usePageState } from '~/context';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import preParsePostFormat from 'dayjs/plugin/preParsePostFormat';
import 'dayjs/locale/ar';
import 'dayjs/locale/ar-SA';
import 'dayjs/locale/en-gb';

dayjs.extend(localizedFormat);
dayjs.extend(preParsePostFormat);

const dateFormat = (culture) => {
  switch (culture) {
    case 'ar-SA':
    case 'ar-AE':
      return 'LL';
    case 'en-GB':
    case 'en-US':
      return 'dddd, LL';
    default:
      return 'LL';
  }
};

const Metadata = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.baseSpacing};
  margin-top: ${({ theme }) => `-${theme.spacing.threeQuarterSpacing}`};
  width: auto;
`;

const Categories = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.halfSpacing};
`;

const Category = styled.a`
  display: inline-flex;
  padding: ${({ theme }) => theme.spacing.halfSpacing};
  border: ${({ theme }) => `1px solid ${theme.colors.textMuted}`};
  border-radius: ${({ theme: { radii } }) => radii.default};
  line-height: 1;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  color: ${({ theme }) => theme.colors.textMuted};
  text-decoration: none;
  box-shadow: ${({ theme }) => theme.boxShadows.subtle};

  &:active {
    box-shadow: ${({ theme }) => theme.boxShadows.selected};
  }
`;

const DateDisplay = styled.time`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  flex-shrink: 0;
`;

const Date = ({ date, culture = 'en-GB' }) => (
  <DateDisplay dateTime={date}>
    {dayjs(date).locale(culture).format(dateFormat(culture))}
  </DateDisplay>
);

/**
 * Used to display blog post metadata
 */
const MetadataBlock = ({ data: { content, key } }) => {
  const { global } = usePageState();
  const { createDate, categories } = content;

  const currentCulture = global.languages?.currentCulture;

  return (
    <Metadata>
      <Date date={createDate} culture={currentCulture?.cultureCode} />
      <Categories>
        {categories.map(
          (category, i) =>
            category.name && (
              <Category href={category.url} key={`${key}-${i}`}>
                {category.name}
              </Category>
            )
        )}
      </Categories>
    </Metadata>
  );
};

export { MetadataBlock };
