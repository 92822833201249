import ReactMarkdown from 'react-markdown';

import { CONTENT_TYPES } from '~/blocks/constants';

const RichText = ({ data, settings }) => {
  const contentType = data?.contentType;
  const anchorID = settings?.anchorID?.trim?.();

  return contentType === CONTENT_TYPES.MARKDOWN ? (
    <div id={anchorID ? anchorID : undefined}>
      <ReactMarkdown>{data?.text}</ReactMarkdown>
    </div>
  ) : (
    <div
      dangerouslySetInnerHTML={{ __html: data?.text }}
      id={anchorID ? anchorID : undefined}
    />
  );
};

export { RichText };
