import { useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  BUTTON_VARIANTS,
  Grid,
  Heading,
  HEADING_VARIANTS,
  Modal,
  Text,
} from '@puregym/ui';

import { usePageState } from '~/context';
import { RichText } from '~/ui';
import { ClassBenefitListBlock, ClassBenefitIcon } from './index';

const ClassBenefitsKey = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.baseSpacing};
  align-items: center;
  text-align: center;
  max-width: 600px;
  margin: auto;
`;

const BenefitHeading = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.baseSpacing};
  align-items: center;

  ${Heading} {
    color: ${({ theme }) => theme.colors.text};
    margin: 0;
  }
`;

const BenefitsGrid = styled(Grid)`
  grid-template-columns: repeat(2, minmax(0, 1fr));
`;

const ClassBenefitsModalContent = ({ benefits, parentKey }) => {
  if (!benefits?.length) return null;

  return (
    <BenefitsGrid>
      {benefits.map((benefit) => {
        const { shortTitle, title, description } = benefit;

        return (
          <div key={`${parentKey}-${shortTitle}`}>
            <BenefitHeading>
              <ClassBenefitIcon
                benefit={benefit}
                displayBenefitName={false}
                size="small"
              />
              <Heading variant={HEADING_VARIANTS.DELTA}>{title}</Heading>
            </BenefitHeading>
            <RichText data={description} />
          </div>
        );
      })}
    </BenefitsGrid>
  );
};

const ClassBenefitGuideBlock = ({ data: { content } }) => {
  const { benefits, description, key, modalTitle, title } = content;
  const [isOpen, setIsOpen] = useState(false);
  const { labels } = usePageState();

  return (
    <>
      <ClassBenefitsKey>
        <Heading>{title}</Heading>
        <ClassBenefitListBlock
          data={{
            content: { benefits: benefits.slice(0, 3) },
            settings: {
              displayBenefitName: false,
              useThemeColors: false,
              variant: 'filled',
            },
          }}
        />
        <Text>{description}</Text>
        <Button
          onClick={() => setIsOpen(!isOpen)}
          variant={BUTTON_VARIANTS.OUTLINE}
        >
          {labels.core.learnMore}
        </Button>
      </ClassBenefitsKey>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        headingText={modalTitle}
        closeText={labels.core.closeMenuButton}
        modalWide={true}
      >
        <ClassBenefitsModalContent benefits={benefits} parentKey={key} />
      </Modal>
    </>
  );
};

export { ClassBenefitGuideBlock };
