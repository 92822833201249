const PAGE_TYPES = {
  BLOG_CATEGORY: 'blogCategoryPage',
  BLOG_POST: 'blogPostPage',
  BLOG: 'blogPage',
  CLASS_CATEGORY_DETAILS_PAGE: 'classCategoryDetailsPage',
  CLASS_CATEGORY_OVERVIEW_PAGE: 'classCategoryOverviewPage',
  CLASS_PAGE: 'classPage',
  ERROR_404: 'error404',
  ERROR_500: 'error500',
  GYM: 'gymPage',
  GYMS: 'gymsPage',
  HELP_CENTRE_CATEGORY: 'helpCentreCategoryPage',
  HELP_CENTRE_QUESTION: 'helpCentreQuestionPage',
  HELP_CENTRE: 'helpCentrePage',
  HOME: 'homePage',
  HTML_SITE_MAP: 'htmlSitemapPage',
  STANDARD: 'standardPage',
};

export { PAGE_TYPES };
