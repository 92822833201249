import styled from 'styled-components';
import Cookies from 'js-cookie';
import { IconLocale, COOKIES } from '@puregym/ui';

import { usePageState } from '~/context';
import { sortLanguages } from '~/utils';

const Languages = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.baseSpacing};
  padding-bottom: ${({ theme }) => theme.spacing.baseSpacing};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.accents.muted}`};

  @media print {
    display: none;
  }
`;

const Language = styled.a`
  display: inline-flex;
  font-size: ${({ theme }) => theme.fontSizes.small};

  &[dir='ltr'] {
    font-family: ${({ theme }) => theme.fonts.body};
  }

  &[dir='rtl'] {
    font-family: ${({ theme }) => theme.rtlFonts.body};
  }
`;

const SimpleLangSwitcher = () => {
  const { global, labels } = usePageState();
  const supportedCultures = global.languages?.supportedCultures || [];
  const currentCulture = global.languages?.currentCulture;

  if (!supportedCultures) return null;

  const languageLinks = [...supportedCultures]
    .sort(sortLanguages)
    .map((culture) => {
      const {
        contentUrl,
        cultureCode,
        languageDisplayName,
        languageName,
        rtl,
      } = culture;
      const isActive = currentCulture.cultureCode === cultureCode;

      return (
        <Language
          as={isActive ? 'span' : 'a'}
          dir={rtl ? 'rtl' : 'ltr'}
          href={!isActive ? contentUrl : undefined}
          isActive={isActive}
          key={`changeCultureListItem-${cultureCode}`}
          onClick={() =>
            Cookies.set(COOKIES.LANGUAGE, cultureCode, { expires: 365 })
          }
          title={languageDisplayName || languageName}
        >
          <span>{languageName}</span>
        </Language>
      );
    });

  return (
    <Languages id="language-switcher">
      <IconLocale title={labels.core?.languageSelectorTitle} />
      {languageLinks}
    </Languages>
  );
};

export { SimpleLangSwitcher };
