import styled from 'styled-components';

const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  padding: ${({ theme }) => theme.spacing.halfSpacing};
  background: rgba(0, 0, 0, 0.66);
  color: #fff;
  font-size: 1rem;
  font-family: Consolas, monaco, monospace;
  text-align: start;

  ul {
    ul {
      margin-top: ${({ theme }) => theme.spacing.halfSpacing};
    }
    > li {
      display: flex;
      justify-content: space-between;
      font-size: ${({ theme }) => theme.fontSizes.small};
      line-height: 1.1;
      > :first-child {
        margin-inline-end: ${({ theme }) => theme.spacing.halfSpacing};
      }
    }
  }
`;

export { Overlay };
