import { maxWidthLayoutStyles } from '@puregym/ui';
import styled from 'styled-components';
import { cloneDeep } from 'lodash-es';

import { usePageState } from '~/context';
import {
  Blocks,
  BlockWrapper,
  BlogPostRelatedPostsBlock,
  BLOCK_TYPES,
} from '~/blocks';
import { SocialShareLinks } from '~/ui';

const PageWrapper = styled(BlockWrapper)`
  background: ${({ theme }) => `linear-gradient(
    180deg,
    ${theme.colors.accents.muted} 0%,
    ${theme.colors.accents.muted} 400px,
    ${theme.colors.background} 400px,
    ${theme.colors.background} 100%
  )`};
`;

const GridWrapper = styled.div`
  ${maxWidthLayoutStyles}
`;

const BlogPostGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.baseSpacing};

  ${({ theme }) => theme.mediaQueries.md} {
    display: grid;
    gap: ${({ theme }) => theme.spacing.doubleSpacing};
    grid-template-columns: 1fr min-content;
  }
`;

const Sidebar = styled.div`
  ${({ theme }) => theme.mediaQueries.md} {
    position: sticky;
    top: 150px;
    margin-top: 150px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    margin-top: 250px;
  }
`;

const RelatedPostsWrapper = styled.div`
  grid-column: span 2;
`;

const BlogPostPage = () => {
  const { page } = usePageState();
  const { blocks, metadata } = page;

  // Insert metadata block into blog content as new "fake" block after the heading
  const clonedBlocks = cloneDeep(blocks);

  const blogPostBlock = clonedBlocks[0].content?.blocks.find(
    (block) => block.contentType === BLOCK_TYPES.BLOG_POST_BLOCK
  );

  const metadataBlock = {
    content: metadata,
    contentType: BLOCK_TYPES.METADATA,
    key: `${blogPostBlock?.key}-meta`,
  };

  blogPostBlock.content.blocks.splice(1, 0, metadataBlock);

  const relatedPosts = clonedBlocks[1]?.content?.blocks.find(
    (block) => block.contentType === BLOCK_TYPES.BLOG_POST_RELATED_POSTS
  );

  const blogPostRelatedPostsBlock = cloneDeep(relatedPosts);

  // Remove the related blog posts; they are rendered below the blog post itself
  if (blogPostRelatedPostsBlock) clonedBlocks.splice(1, 1);

  return (
    <PageWrapper>
      <GridWrapper>
        <BlogPostGrid>
          <div>
            <Blocks data={clonedBlocks} blockLevel={1} />
          </div>
          {metadata.shareOptions && (
            <div>
              <Sidebar>
                <SocialShareLinks links={metadata.shareOptions} />
              </Sidebar>
            </div>
          )}
          {blogPostRelatedPostsBlock && (
            <RelatedPostsWrapper>
              <BlogPostRelatedPostsBlock data={blogPostRelatedPostsBlock} />
            </RelatedPostsWrapper>
          )}
        </BlogPostGrid>
      </GridWrapper>
    </PageWrapper>
  );
};

export { BlogPostPage };
