import { Button, BUTTON_VARIANTS } from '@puregym/ui';

import { BLANK_TARGET } from '~/constants';
import { getLinkTarget } from '~/utils/urls';

const ButtonBlock = ({ data: { content, settings } }) => {
  const { link } = content;
  if (!link) return null;

  const { name, target, url } = link;
  const forceNewTab = target === BLANK_TARGET;
  const variant = settings?.variant || BUTTON_VARIANTS.SECONDARY;

  return (
    <Button
      as="a"
      href={url}
      rel={forceNewTab ? 'noopener' : null}
      target={forceNewTab ? BLANK_TARGET : null}
      data-linktarget={getLinkTarget(url)}
      variant={variant}
    >
      {name}
    </Button>
  );
};

export { ButtonBlock };
