import { useState } from 'react';
import styled from 'styled-components';
import { Heading, SearchForm } from '@puregym/ui';

import { usePageState } from '~/context';
import map from '~/assets/img/map-bg.svg';

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.baseSpacing};
  padding: ${({ theme }) => theme.spacing.doubleSpacing};
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.contrastText};
  text-align: center;

  ${Heading} {
    color: ${({ theme }) => theme.colors.contrastText};
    margin: 0;
  }

  form {
    width: 100%;
  }

  img {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    padding: ${({ theme }) =>
      `${theme.BASE_SPACING * 4}px ${theme.spacing.doubleSpacing}`};
  }
`;

const SubText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const FindYourGymBlock = ({ data: { content } }) => {
  const { labels } = usePageState();
  const { title, subTitle, text, url, placeholder } = content;
  const [searchTerm, setSearchTerm] = useState('');

  if (!url) return null;

  const searchUrl = searchTerm
    ? `${url}?search=${encodeURIComponent(searchTerm)}`
    : `${url}`;

  const onChange = (e) => {
    setSearchTerm(e.target.value.trim());
  };

  const onSubmit = (e) => {
    e.preventDefault();
    window.location.href = searchUrl;
  };

  return (
    <Wrapper>
      <Heading as="h2">{title}</Heading>
      <p>{subTitle}</p>
      <SearchForm
        action={url}
        buttonText={labels.core?.search}
        data-testid="findYourGymSearchForm"
        hideLabel={true}
        inputProps={{ 'data-testid': 'findYourGymSearchInput' }}
        label={title}
        onChange={onChange}
        onSubmit={onSubmit}
        placeholder={placeholder}
        showGeoLocation={false}
      />
      <SubText>{text}</SubText>
      <img src={map} alt="" aria-hidden={true} />
    </Wrapper>
  );
};

export { FindYourGymBlock };
