import { Grid } from '@puregym/ui';

import { CtaBlock } from './ctaBlock';

const CtaListBlock = ({ data: { content } }) => {
  return (
    <Grid minItemWidth="260px">
      {content.actionList.map((callToAction) => {
        return <CtaBlock data={callToAction} key={callToAction.key} />;
      })}
    </Grid>
  );
};

export { CtaListBlock };
