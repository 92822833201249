/**
 * Sort language objects array based on culture code.
 * We sort by code to maintain sort order regardless of locale.
 * e.g. Arabic (العربية) appears under 'A' because the code is 'ar'.
 */
const sortLanguages = (a, b) => {
  try {
    const codeA = a.cultureCode.toLocaleLowerCase();
    const codeB = b.cultureCode.toLocaleLowerCase();

    return codeA.localeCompare(codeB);
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      console.error('Failed to sort languages:', e);
    }
  }
};

export { sortLanguages };
