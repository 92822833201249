import { Button, Grid, HEADING_VARIANTS, Heading, Text } from '@puregym/ui';
import { ImageBlock } from '~/blocks';

const LatestBlogPostBlock = ({ data: { content } }) => {
  const { buttonLabel, heading, latestBlog } = content;

  if (!latestBlog) return null;

  const { excerpt, name, url, primaryPostImage } = latestBlog;

  return (
    <>
      <Heading variant={HEADING_VARIANTS.BETA}>{heading}</Heading>
      <Grid>
        <div>
          <ImageBlock
            data={{
              content: primaryPostImage,
            }}
          />
        </div>
        <div>
          <Heading variant={HEADING_VARIANTS.GAMMA}>{name}</Heading>
          <Text>{excerpt}</Text>
          <Button as="a" href={url}>
            {buttonLabel}
          </Button>
        </div>
      </Grid>
    </>
  );
};

export { LatestBlogPostBlock };
