import { useState } from 'react';
import styled, { css } from 'styled-components';
import { getVideoUrl } from '~/utils';
import { VIDEO_TYPES } from '~/constants';

const PlayButton = styled.button`
  width: 70px;
  height: 46px;
  background-color: #212121;
  z-index: 1;
  opacity: 0.8;
  border-radius: 14%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
  border: none;
  cursor: pointer;

  &::before {
    content: '';
    border-color: transparent transparent transparent #fff;
    border-style: solid;
    border-width: 11px 0 11px 19px;
  }

  &,
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &:hover,
  &:focus,
  &:active {
    background-color: red;
    opacity: 1;
  }
`;

const activatedStyles = css`
  cursor: unset;

  &::before,
  ${PlayButton} {
    opacity: 0;
    pointer-events: none;
  }

  &:hover,
  &:focus,
  &:active {
    ${PlayButton} {
      opacity: 0;
      pointer-events: none;
    }
  }
`;

const IframeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%; // 16:9 aspect ratio
  border-radius: ${({ theme }) => theme.radii.default};
  overflow: hidden;

  iframe {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const Wrapper = styled.article`
  position: relative;
  display: block;
  contain: content;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.radii.default};
  background: ${({ posterUrl }) => `#000 url(${posterUrl}) 50% / cover`};
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
    background-repeat: repeat-x;
    background-size: auto 100%;
    width: 100%;
    height: 100%;
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
  }

  &:hover,
  &:focus,
  &:active {
    ${PlayButton} {
      background-color: red;
      opacity: 1;
    }
  }

  ${({ activated }) => activated && activatedStyles};
`;

const YouTubeEmbed = ({
  buttonText,
  id,
  iframeProps,
  title,
  autoplay,
  ...rest
}) => {
  const [isPreconnected, setIsPreconnected] = useState(false);
  const [iframe, setIframe] = useState(false);

  const videoId = encodeURIComponent(id);
  const ytUrl = 'https://www.youtube-nocookie.com';
  const iframeSrc = getVideoUrl(id, VIDEO_TYPES.YOUTUBE, false, autoplay);
  const posterUrl = `https://i.ytimg.com/vi_webp/${videoId}/hqdefault.webp`;

  const warmConnections = () => {
    if (isPreconnected) return;
    setIsPreconnected(true);
  };

  const addIframe = () => {
    if (iframe) return;
    setIframe(true);
  };

  return (
    <>
      {isPreconnected && (
        <>
          <link rel="preconnect" href={ytUrl} />
          <link rel="preconnect" href="https://www.google.com" />
        </>
      )}
      <Wrapper
        activated={iframe}
        onClick={addIframe}
        onPointerOver={warmConnections}
        data-title={title}
        posterUrl={posterUrl}
        {...rest}
      >
        <IframeContainer>
          {!autoplay && <PlayButton aria-label={buttonText || title} />}
          <iframe
            src={iframeSrc}
            title={title}
            frameBorder="0"
            allow="autoplay; encrypted-media; fullscreen; picture-in-picture"
            {...iframeProps}
          ></iframe>
        </IframeContainer>
      </Wrapper>
    </>
  );
};

export { YouTubeEmbed };
