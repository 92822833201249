import { Arrow, List, ListItem, LIST_VARIANTS } from '@puregym/ui';
import { usePageState } from '~/context';

const LinkListItemBlock = ({ data: { content }, isRtl }) => {
  const { href, text } = content;

  return (
    <ListItem>
      <a href={href}>
        <span>{text}</span>
        <Arrow arrowDirection="forward" isRtl={isRtl} aria-hidden={true} />
      </a>
    </ListItem>
  );
};

const LinkListBlock = ({ data: { content, settings } }) => {
  const { global } = usePageState();
  const isRtl = global.languages.currentCulture?.rtl;
  const variant = settings?.variant || LIST_VARIANTS.PLAIN;

  return (
    <List variant={variant}>
      {content.blocks.map((item) => (
        <LinkListItemBlock data={item} key={item.key} isRtl={isRtl} />
      ))}
    </List>
  );
};

export { LinkListBlock, LinkListItemBlock };
