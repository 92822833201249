import styled, { css } from 'styled-components';
import { Heading, useTheme } from '@puregym/ui';

import { usePageState } from '~/context';
import { CATEGORY_THEME_COLORS } from '~/blocks';
import { TEXT_ALIGNMENTS } from './constants';

const StyledHeading = styled(Heading)`
  margin: 0;
  color: ${({ headingColor }) => headingColor};

  ${({ alignment }) => {
    switch (alignment) {
      case TEXT_ALIGNMENTS.CENTER:
        return css`
          text-align: center;
          align-self: center;
        `;
      case TEXT_ALIGNMENTS.LEFT:
        return css`
          text-align: start;
          align-self: start;
        `;
      case TEXT_ALIGNMENTS.RIGHT:
        return css`
          text-align: end;
          align-self: end;
        `;
      // no-default
    }
  }};
`;

const HeadingBlock = ({ data: { content, settings } }) => {
  const { page } = usePageState();
  const { colors } = useTheme();

  const { headingText } = content;
  const pageTheme = page.metadata?.theme;
  const alignment = settings?.alignment;
  const variant = settings?.variant?.toLowerCase();
  const headingLevel = settings?.headingLevel?.toLowerCase();
  const applyThemeColor = settings?.applyThemeColor;
  const anchorID = settings?.anchorID?.trim?.();
  let headingColor;

  if (pageTheme && applyThemeColor) {
    switch (pageTheme) {
      case CATEGORY_THEME_COLORS.BLUE:
        headingColor = '#1A4B72';
        break;
      case CATEGORY_THEME_COLORS.RED:
        headingColor = '#F1605A';
        break;
      case CATEGORY_THEME_COLORS.GREY:
        headingColor = '#9BCDC9';
        break;
      case CATEGORY_THEME_COLORS.BLACK:
        headingColor = '#0d0d0d';
        break;
      case CATEGORY_THEME_COLORS.WHITE:
        headingColor = colors.text;
        break;
      // no-default
    }
  }

  return (
    <StyledHeading
      as={headingLevel}
      variant={variant}
      alignment={alignment}
      headingColor={headingColor}
      id={anchorID ? anchorID : undefined}
    >
      {headingText}
    </StyledHeading>
  );
};

export { HeadingBlock };
