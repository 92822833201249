import styled from 'styled-components';
import { ListItem, LIST_VARIANTS, List } from '@puregym/ui';

const ServerInfo = styled.details`
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: ${({ theme }) => theme.fontSizes.xxsmall};
`;

const ServerInfoSummary = styled.summary`
  margin-top: ${({ theme }) => theme.spacing.baseSpacing};
  font-size: ${({ theme }) => theme.fontSizes.small};
  cursor: pointer;
`;

const InfoList = styled(List)`
  font-size: ${({ theme }) => theme.fontSizes.xxsmall};
`;

const Identify = ({ serverSupportData }) => {
  if (!serverSupportData) return null;

  return (
    <ServerInfo dir="ltr">
      <ServerInfoSummary>Server info</ServerInfoSummary>
      <InfoList variant={LIST_VARIANTS.PLAIN}>
        <ListItem>
          Version:
          <a
            href={`https://puregym.atlassian.net/projects/PG?selectedItem=com.atlassian.jira.jira-projects-plugin:release-page&status=all&contains=${serverSupportData.version}`}
          >
            <strong>{serverSupportData.version}</strong>
          </a>
        </ListItem>
        <ListItem>
          Identity: <strong>{serverSupportData.identity}</strong>
        </ListItem>
        <ListItem>
          Machine Name: <strong>{serverSupportData.machineName}</strong>
        </ListItem>
        <ListItem>
          Umbraco Role: <strong>{serverSupportData.umbracoRole}</strong>
        </ListItem>
        <ListItem>
          Azure Site Name: <strong>{serverSupportData.azureSiteName}</strong>
        </ListItem>
        {serverSupportData.instances.map((item, i) => (
          <ListItem key={`${item}${i}`}>
            Instance:
            {item.value ? (
              <strong>{item.key} - current</strong>
            ) : (
              <a
                href={`/SetArrAffinityCookie?arrAffinityCookieValue=${item.key}`}
              >
                {item.key}
              </a>
            )}
          </ListItem>
        ))}
        <ListItem>
          Bitness: <strong>{serverSupportData.bitness}</strong>
        </ListItem>
        {serverSupportData.connectionStrings.map((item, i) => (
          <ListItem key={`${item}${i}`}>{item}</ListItem>
        ))}
        <ListItem>
          Feature Switches:
          <strong>{serverSupportData.featureSwitches.join()}</strong>
        </ListItem>
      </InfoList>
    </ServerInfo>
  );
};

export { Identify };
