import { useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Button, BUTTON_VARIANTS, Modal } from '@puregym/ui';

import { usePageState } from '~/context';
import { Blocks } from '~/blocks';
import { Grid } from './blockStyles';

const ModalStyles = createGlobalStyle`
  .ReactModal__Overlay { padding: 6px !important; }

  .ReactModal__Content {
    @media (max-width: 768px) {  
      min-width: 100%;
      padding: 12px !important;
    }
  }
`;

const ModalBlock = ({ data: { content, settings } }) => {
  const { labels } = usePageState();
  const [isOpen, setIsOpen] = useState(false);

  const { buttonText, modalHeading, modalContent } = content;
  const buttonVariant = settings?.variant || BUTTON_VARIANTS.SECONDARY;

  const onClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ModalStyles />
      <Button variant={buttonVariant} onClick={onClick}>
        {buttonText}
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        headingText={modalHeading}
        closeText={labels.core.closeMenuButton}
      >
        <Grid>
          <Blocks data={modalContent} />
        </Grid>
      </Modal>
    </>
  );
};

export { ModalBlock };
