import { Button, BUTTON_VARIANTS } from '@puregym/ui';
import styled from 'styled-components';

import { LINK_TARGETS } from '~/constants';

const JoinButton = styled(Button)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }) =>
    `${theme.spacing.baseSpacing} ${theme.spacing.threeQuarterSpacing}`};
  background-color: ${({ theme }) => theme.colors.background};
  border-color: ${({ theme }) => theme.colors.primary.main};
  border-width: 2px;
  text-align: center;

  > span {
    transition: ${({ theme }) => theme.transitionsMs.default};
  }

  .heading {
    font-size: 2.2rem;
    color: ${({ theme }) => theme.colors.text};
  }

  .subHeading {
    color: ${({ theme }) => theme.colors.primary.main};
    text-transform: none;
  }

  &:not(:disabled) {
    &:hover,
    &:focus,
    &:active {
      background-color: ${({ theme }) => theme.colors.secondary.main};
      border-color: ${({ theme }) => theme.colors.secondary.main};
      > span {
        color: ${({ theme }) => theme.colors.text};
      }
    }
  }
`;

const JoinButtonBlock = ({ data: { content, settings } }) => {
  const variant = settings?.variant || BUTTON_VARIANTS.OUTLINE;
  const { clientCode, heading, joinLink, promotionCode, subHeading } = content;
  const { url } = joinLink;

  if (!url) return null;

  let fullUrl = url;

  if (clientCode) {
    const encodedCorporateAgreementId = encodeURIComponent(clientCode);

    fullUrl += fullUrl.includes('?')
      ? `&corporateAgreementId=${encodedCorporateAgreementId}`
      : `?corporateAgreementId=${encodedCorporateAgreementId}`;
  }

  if (promotionCode) {
    const encodedPromotionCode = encodeURIComponent(promotionCode);

    fullUrl += fullUrl.includes('?')
      ? `&promoCode=${encodedPromotionCode}`
      : `?promoCode=${encodedPromotionCode}`;
  }

  return (
    <JoinButton
      as="a"
      data-linktarget={LINK_TARGETS.JOIN}
      href={fullUrl}
      variant={variant}
    >
      <span className="heading">{heading}</span>
      <span className="subHeading">{subHeading}</span>
    </JoinButton>
  );
};

export { JoinButtonBlock };
