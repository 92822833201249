import { useTheme } from '@puregym/ui';

import { Wrapper } from '~/ui';

import {
  AccordionBlock,
  BlockMetadata,
  Blocks,
  BlogCategoryLinkListBlock,
  BlogPostLinkBlock,
  BlogPostLinkListBlock,
  BlogPostRelatedPostsBlock,
  BreadcrumbBlock,
  BreadcrumbItemBlock,
  ButtonBlock,
  CardBlock,
  CardListBlock,
  CarouselBlock,
  CategoryLinkBlock,
  CategoryLinkListBlock,
  ClassBenefitGuideBlock,
  ClassBenefitListBlock,
  ClassInfoBlock,
  ContactLinkBlock,
  ContactLinkListBlock,
  CtaBlock,
  CtaListBlock,
  FindYourGymBlock,
  FitnessClassCategoriesBlock,
  HeadingBlock,
  HeroBlock,
  IframeBlock,
  ImageBlock,
  JoinButtonBlock,
  LatestBlogPostBlock,
  LinkListBlock,
  ListBlock,
  MetadataBlock,
  ModalBlock,
  ParagraphBlock,
  PureChatFrameworkBlock,
  PureChatLaunchBlock,
  QuestionLinkBlock,
  QuestionLinkListBlock,
  QuoteBlock,
  RelatedClassesListBlock,
  RichTextBlock,
  SplitBgBlock,
  TabsBlock,
  TrustpilotBlock,
  U16GymPickerBlock,
  VideoBlock,
} from './index';

import {
  BLOCK_TYPES,
  CATEGORY_THEME_COLORS,
  CONTENT_TYPES,
  LAYOUT_BLOCK_TYPES,
  THEME_COLOR_PALETTE_TYPES,
  THEME_COLOR_TYPES,
  UI_BLOCK_TYPES,
} from './constants';

import { BlockWrapper, GridWrapper, Grid } from './blockStyles';
import { usePageState } from '~/context';

/** Set the number of cols in the grid based on the block type */
const gridCols = (blockType) => {
  switch (blockType) {
    case BLOCK_TYPES.TWO_COLUMN:
    case BLOCK_TYPES.SPLIT_BACKGROUND:
      return 2;
    case BLOCK_TYPES.THREE_COLUMN:
      return 3;
    case BLOCK_TYPES.FOUR_COLUMN:
      return 4;
    default:
      return 1;
  }
};

/** The actual rendering of UI blocks */
const renderUIBlock = (block) => {
  const { contentType, key } = block;

  switch (contentType) {
    case BLOCK_TYPES.ACCORDION:
      return <AccordionBlock data={block} key={key} />;

    case BLOCK_TYPES.BLOG_CATEGORY_LINK_LIST:
      return <BlogCategoryLinkListBlock data={block} key={key} />;

    case BLOCK_TYPES.BLOG_POST_LINK:
      return <BlogPostLinkBlock data={block} key={key} />;

    case BLOCK_TYPES.BLOG_POST_LINK_LIST:
      return <BlogPostLinkListBlock data={block} key={key} />;

    case BLOCK_TYPES.BLOG_POST_RELATED_POSTS:
      return <BlogPostRelatedPostsBlock data={block} key={key} />;

    case BLOCK_TYPES.BUTTON_LINK:
      return <ButtonBlock data={block} key={key} />;

    case BLOCK_TYPES.BREADCRUMB:
      return <BreadcrumbBlock data={block} key={key} />;

    case BLOCK_TYPES.BREADCRUMB_ITEM:
      return <BreadcrumbItemBlock data={block} key={key} />;

    case BLOCK_TYPES.CARD:
      return <CardBlock data={block} key={key} />;

    case BLOCK_TYPES.CARD_LIST:
      return <CardListBlock data={block} key={key} />;

    case BLOCK_TYPES.CAROUSEL:
      return <CarouselBlock data={block} key={key} />;

    case BLOCK_TYPES.CATEGORY_LINK:
      return <CategoryLinkBlock data={block} key={key} />;

    case BLOCK_TYPES.CATEGORY_LINK_LIST:
      return <CategoryLinkListBlock data={block} key={key} />;

    case BLOCK_TYPES.CLASS_BENEFIT_LIST:
      return <ClassBenefitListBlock data={block} key={key} />;

    case BLOCK_TYPES.CLASS_INFO:
      return <ClassInfoBlock data={block} key={key} />;

    case BLOCK_TYPES.CONTACT_LINK:
      return <ContactLinkBlock data={block} key={key} />;

    case BLOCK_TYPES.CONTACT_LINK_LIST:
      return <ContactLinkListBlock data={block} key={key} />;

    case BLOCK_TYPES.CHAT_FRAMEWORK:
      return <PureChatFrameworkBlock data={block} key={key} />;

    case BLOCK_TYPES.CHAT_LAUNCH:
      return <PureChatLaunchBlock data={block} key={key} />;

    case BLOCK_TYPES.CTA:
      return <CtaBlock data={block} key={key} />;

    case BLOCK_TYPES.CTA_LIST:
      return <CtaListBlock data={block} key={key} />;

    case BLOCK_TYPES.HEADING:
      return <HeadingBlock data={block} key={key} />;

    case BLOCK_TYPES.HERO:
    case BLOCK_TYPES.HELP_CENTRE_HEADING:
      return <HeroBlock data={block} key={key} />;

    case BLOCK_TYPES.IFRAME:
      return <IframeBlock data={block} key={key} />;

    case BLOCK_TYPES.JOIN_BUTTON:
      return <JoinButtonBlock data={block} key={key} />;

    case BLOCK_TYPES.LATEST_BLOG_POST:
      return <LatestBlogPostBlock data={block} key={key} />;

    case BLOCK_TYPES.LINK_LIST:
      return <LinkListBlock data={block} key={key} />;

    case BLOCK_TYPES.LIST:
      return <ListBlock data={block} key={key} />;

    case BLOCK_TYPES.METADATA:
      return <MetadataBlock data={block} key={key} />;

    case BLOCK_TYPES.PARAGRAPH:
      return <ParagraphBlock data={block} key={key} />;

    case BLOCK_TYPES.QUESTION_LINK:
      return <QuestionLinkBlock data={block} key={key} />;

    case BLOCK_TYPES.QUESTION_LINK_LIST:
      return <QuestionLinkListBlock data={block} key={key} />;

    case BLOCK_TYPES.QUOTE:
      return <QuoteBlock data={block} key={key} />;

    case BLOCK_TYPES.RELATED_CLASSES_LIST:
      return <RelatedClassesListBlock data={block} key={key} />;

    case BLOCK_TYPES.FITNESS_CLASS_CATEGORIES:
      return <FitnessClassCategoriesBlock data={block} key={key} />;

    case BLOCK_TYPES.CLASS_BENEFIT_GUIDE:
      return <ClassBenefitGuideBlock data={block} key={key} />;

    case BLOCK_TYPES.RICH_TEXT:
      return <RichTextBlock data={block} key={key} />;

    case BLOCK_TYPES.SMALL_IMAGE:
    case BLOCK_TYPES.RESPONSIVE_IMAGE:
      return <ImageBlock data={block} key={key} />;

    case BLOCK_TYPES.SPLIT_BACKGROUND:
      return <SplitBgBlock data={block} key={key} />;

    case BLOCK_TYPES.TABS:
      return <TabsBlock data={block} />;

    case BLOCK_TYPES.VIDEO:
      return <VideoBlock data={block} key={key} />;

    case BLOCK_TYPES.MODAL:
      return <ModalBlock data={block} key={key} />;

    case BLOCK_TYPES.FIND_YOUR_GYM:
      return <FindYourGymBlock data={block} key={key} />;

    case BLOCK_TYPES.TRUSTPILOT:
      return <TrustpilotBlock data={block} key={key} />;

    case BLOCK_TYPES.U16_GYM_PICKER:
      return <U16GymPickerBlock data={block} key={key} />;

    default:
      return process.env.NODE_ENV === 'development' ? (
        <span>Unknown block type `{contentType}`</span>
      ) : null;
  }
};

/**
 * Renders blocks recursively. Each layout block at the base level is rendered
 * in a full-width wrapper (necessary to achieve bg images/colours).
 *
 * @param {object} block
 * @param {number} blockLevel
 * @param {bool} debug
 * @param {bool} equalHeight
 */
const Block = ({
  block,
  blockLevel = 0,
  debug = false,
  equalHeight = false,
}) => {
  const { colors } = useTheme();
  const { content, contentType, settings } = block;
  const { page } = usePageState();
  const pageTheme = page.metadata?.theme;

  // If we're at the UI level, render block with no additional markup
  if (Object.values(UI_BLOCK_TYPES).includes(contentType)) {
    return renderUIBlock(block);
  }

  /**
   * If the settings specify it, we can alter:
   * - Layout (default equal columns; see Grid styles)
   * - Alignment (@see https://developer.mozilla.org/en-US/docs/Web/CSS/align-items)
   * - Background color
   * - Text color (based on bg color)
   */
  const layout = settings?.layout;
  const textAlignment = settings?.textAlignment;
  const verticalAlignment = settings?.verticalAlignment;
  const isEqualHeight = settings?.equalHeight || equalHeight;
  const anchorID = settings?.anchorID?.trim?.();
  let bgColor;
  let textColor;

  // If we are using a theme color, match the name with the active theme
  if (settings?.backgroundColour?.contentType === CONTENT_TYPES.THEME_COLOR) {
    const { name, type } = settings.backgroundColour;

    // Is this a specific color `type`, or top-level ("general")?
    const isGeneralColor = type === THEME_COLOR_TYPES.GENERAL;
    const colorObject = isGeneralColor ? colors : colors[type];
    const isPaletteColor = Object.values(THEME_COLOR_PALETTE_TYPES).includes(
      type
    );
    const isLightColor = name.toLowerCase().startsWith('light');
    const isDarkColor = name.toLowerCase().startsWith('dark');
    const isTextColor = name.toLowerCase().includes('text');

    if (isPaletteColor) {
      const specificContrastText = isLightColor
        ? colorObject.contrastTextLight
        : colorObject.contrastText;

      textColor = specificContrastText;
    } else {
      // If we're not on top of a dark color, don't specify a textColor
      textColor = isDarkColor ? colors.contrastText : null;
    }

    if (colorObject && !isTextColor) {
      bgColor = colorObject[name];
    }
  }

  // Custom page theme (Classes pages)
  if (pageTheme && settings.applyThemeColor) {
    switch (pageTheme) {
      case CATEGORY_THEME_COLORS.BLUE:
        bgColor = '#1A4B72';
        textColor = colors.contrastText;
        break;
      case CATEGORY_THEME_COLORS.RED:
        bgColor = '#F1605A';
        textColor = colors.text;
        break;
      case CATEGORY_THEME_COLORS.GREY:
        bgColor = '#9BCDC9';
        textColor = colors.contrastText;
        break;
      case CATEGORY_THEME_COLORS.BLACK:
        bgColor = '#0d0d0d';
        textColor = colors.contrastText;
        break;
      case CATEGORY_THEME_COLORS.WHITE:
        textColor = colors.text;
      // no-default
    }
  }

  // Conditionally wrap block(s) if this is a layout block at the base level
  const isBaseBlock =
    Object.values(LAYOUT_BLOCK_TYPES).includes(contentType) && blockLevel === 0;

  return (
    <Wrapper
      condition={isBaseBlock}
      wrapper={(children) => (
        <BlockWrapper
          id={anchorID ? anchorID : undefined}
          bgColor={bgColor}
          textColor={textColor}
          layout={layout}
        >
          <GridWrapper>{children}</GridWrapper>
        </BlockWrapper>
      )}
    >
      <Grid
        blockLevel={blockLevel}
        blockType={contentType}
        columns={gridCols(contentType)}
        debug={debug}
        equalHeight={isEqualHeight}
        layout={layout}
        textColor={textColor}
        textAlignment={textAlignment}
        verticalAlignment={verticalAlignment}
      >
        {/* Render child blocks if present, else render the block */}
        {content.blocks ? (
          <Blocks
            blockLevel={(blockLevel += 1)}
            data={content.blocks}
            equalHeight={isEqualHeight}
          />
        ) : (
          renderUIBlock(block)
        )}
        {debug && settings && <BlockMetadata data={settings} />}
      </Grid>
    </Wrapper>
  );
};

export { Block, BlockWrapper, Grid, GridWrapper, renderUIBlock };
