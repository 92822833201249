const KIOSK = 'kiosk'; // Registration kiosks, outside gyms
const MEMBER_APP = 'PureGymMemberApp'; // Our internal iOS/Android apps
const VENDOR_APP = 'puregym-vendor-app'; // Yanga water

const EMBEDDED_USER_AGENTS = {
  KIOSK,
  MEMBER_APP,
  VENDOR_APP,
};

export { KIOSK, MEMBER_APP, VENDOR_APP, EMBEDDED_USER_AGENTS };
