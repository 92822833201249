import styled, { css } from 'styled-components';
import { YouTubeEmbed } from '~/ui';
import { getVideoUrl } from '~/utils';
import { VIDEO_TYPES } from '~/constants';
import { BLOCK_SIZES } from './constants';

const VideoWrapper = styled.div`
  width: 100%;
  margin: auto;

  ${({ theme }) => theme.mediaQueries.md} {
    .ReactModal__Content & {
      min-width: 620px;
    }

    ${({ size }) => {
      switch (size) {
        case BLOCK_SIZES.SMALL:
          return css`
            max-width: 50%;
          `;
        case BLOCK_SIZES.MEDIUM:
          return css`
            max-width: 80%;
          `;
        // no default
      }
    }}
  }
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%; // 16:9 aspect ratio
  border-radius: ${({ theme }) => theme.radii.default};
  overflow: hidden;

  iframe {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const VideoBlock = ({ data: { content, key, settings } }) => {
  const { description, title, videoId, videoType, autoplay } = content;
  const anchorID = settings?.anchorID?.trim?.();

  return (
    <VideoWrapper size={settings?.size} id={anchorID ? anchorID : undefined}>
      {videoType === VIDEO_TYPES.YOUTUBE ? (
        <YouTubeEmbed
          buttonText={title}
          id={videoId}
          title={description}
          autoplay={autoplay}
          iframeProps={{ 'data-testid': key }}
        />
      ) : (
        <VideoContainer>
          <iframe
            data-testid={key}
            src={getVideoUrl(videoId, videoType, false, autoplay)}
            title={description}
            allow="autoplay; encrypted-media; fullscreen; picture-in-picture"
          ></iframe>
        </VideoContainer>
      )}
    </VideoWrapper>
  );
};

export { VideoBlock };
