import {
  Icon360Tour,
  IconAccessibility,
  IconAccount,
  IconAccountCircle,
  IconArrowDown,
  IconArrowDownAlt,
  IconArrowLeft,
  IconArrowRight,
  IconArrowUp,
  IconAsterisk,
  IconBook,
  IconBuilding,
  IconCalendar,
  IconChat,
  IconCheck,
  IconCheckCircle,
  IconCircleEmpty,
  IconClassEndurance,
  IconClassFatburn,
  IconClassFullbody,
  IconClassMobility,
  IconClassStrength,
  IconClassTargetArea,
  IconClassTone,
  IconClassWellbeing,
  IconClock,
  IconClose,
  IconColorPalette,
  IconColorPicker,
  IconCookieBite,
  IconCreditCard,
  IconCvv,
  IconDash,
  IconDocuments,
  IconDoorOpen,
  IconEmail,
  IconFacebook,
  IconFullscreen,
  IconFullscreenExit,
  IconGrid,
  IconGymFeature100FreeClasses,
  IconGymFeature150FreeClasses,
  IconGymFeature200FreeClasses,
  IconGymFeature200Kit,
  IconGymFeature200KitMachine,
  IconGymFeature220Kit,
  IconGymFeature2Floors,
  IconGymFeature3Floors,
  IconGymFeature40FreeClasses,
  IconGymFeature50FreeClasses,
  IconGymFeature60FreeClasses,
  IconGymFeature70FreeClasses,
  IconGymFeature80FreeClasses,
  IconGymFeature90FreeClasses,
  IconGymFeature50Kg,
  IconGymFeatureApp,
  IconGymFeatureBodyTrak,
  IconGymFeatureClasses,
  IconGymFeatureCleaning,
  IconGymFeatureDigitalClass,
  IconGymFeatureDigitalCycle,
  IconGymFeatureDumbbells50Kg,
  IconGymFeatureFreeParking,
  IconGymFeatureFreeWeightsKettle,
  IconGymFeatureHydromassage,
  IconGymFeatureKeyFob,
  IconGymFeatureLadiesOnlyArea,
  IconGymFeatureLargeFreeWeightsArea,
  IconGymFeatureNewKit,
  IconGymFeatureNewlyRefurbished,
  IconGymFeatureOpen24Hours,
  IconGymFeaturePtAvailable,
  IconGymFeatureQualityKit,
  IconGymFeatureShowers,
  IconGymFeatureYanga,
  IconGymPackageFeatureAdvancedBooking,
  IconGymPackageFeatureBenefits,
  IconGymPackageFeatureBuddy,
  IconGymPackageFeatureMultiGym,
  IconHand,
  IconHeart,
  IconIdCard,
  IconInfo,
  IconInstagram,
  IconList,
  IconLocale,
  IconLock,
  IconLogIn,
  IconLogOut,
  IconMap,
  IconMapPin,
  IconMenu,
  IconMessenger,
  IconMultiGym,
  IconNfc,
  IconNoContract,
  IconPhone,
  IconPlus,
  IconQrCode,
  IconSearch,
  IconSnowflake,
  IconStar,
  IconStarHalf,
  IconStrava,
  IconTarget,
  IconThemeDark,
  IconThemeLight,
  IconTicket,
  IconTiktok,
  IconTimes,
  IconTwitter,
  IconUnlock,
  IconWarning,
  IconWeights,
  IconWhatsapp,
  IconWifi,
  IconYoutube,
  IconArrowUpAlt,
} from '@puregym/ui';

/* eslint camelcase: "off" */
const icons = {
  'class-endurance': IconClassEndurance,
  'class-fatburn': IconClassFatburn,
  'class-fullbody': IconClassFullbody,
  'class-mobility': IconClassMobility,
  'class-strength': IconClassStrength,
  'class-target-area': IconClassTargetArea,
  'class-tone': IconClassTone,
  'class-wellbeing': IconClassWellbeing,
  '360Tour': Icon360Tour,
  accessibility: IconAccessibility,
  account: IconAccount,
  'account-circle': IconAccountCircle,
  'arrow-down': IconArrowDown,
  'arrow-down-alt': IconArrowDownAlt,
  'arrow-left': IconArrowLeft,
  'arrow-right': IconArrowRight,
  'arrow-up': IconArrowUp,
  'arrow-up-alt': IconArrowUpAlt,
  asterisk: IconAsterisk,
  book: IconBook,
  building: IconBuilding,
  calendar: IconCalendar,
  chat: IconChat,
  check: IconCheck,
  'check-circle': IconCheckCircle,
  circleEmpty: IconCircleEmpty,
  clock: IconClock,
  close: IconClose,
  'color-palette': IconColorPalette,
  'color-picker': IconColorPicker,
  'cookie-bite': IconCookieBite,
  'credit-card': IconCreditCard,
  cvv: IconCvv,
  dash: IconDash,
  documents: IconDocuments,
  'door-open': IconDoorOpen,
  email: IconEmail,
  facebook: IconFacebook,
  fullscreen: IconFullscreen,
  'fullscreen-exit': IconFullscreenExit,
  grid: IconGrid,
  gymFeature100FreeClasses: IconGymFeature100FreeClasses,
  gymFeature150FreeClasses: IconGymFeature150FreeClasses,
  gymFeature200FreeClasses: IconGymFeature200FreeClasses,
  gymFeature200Kit: IconGymFeature200Kit,
  gymFeature200KitMachine: IconGymFeature200KitMachine,
  gymFeature2Floors: IconGymFeature2Floors,
  gymFeature220Kit: IconGymFeature220Kit,
  gymFeature3Floors: IconGymFeature3Floors,
  gymFeature40FreeClasses: IconGymFeature40FreeClasses,
  gymFeature50FreeClasses: IconGymFeature50FreeClasses,
  gymFeature50kg: IconGymFeature50Kg,
  gymFeature60FreeClasses: IconGymFeature60FreeClasses,
  gymFeature70FreeClasses: IconGymFeature70FreeClasses,
  gymFeature80FreeClasses: IconGymFeature80FreeClasses,
  gymFeature90FreeClasses: IconGymFeature90FreeClasses,
  gymFeatureApp: IconGymFeatureApp,
  gymFeatureBodyTrak: IconGymFeatureBodyTrak,
  GymFeatureCleaning: IconGymFeatureCleaning,
  gymFeatureClasses: IconGymFeatureClasses,
  gymFeatureDigitalClass: IconGymFeatureDigitalClass,
  gymFeatureDigitalCycle: IconGymFeatureDigitalCycle,
  gymFeatureDumbbells50kg: IconGymFeatureDumbbells50Kg,
  gymFeatureFreeParking: IconGymFeatureFreeParking,
  gymFeatureFreeWeightsKettle: IconGymFeatureFreeWeightsKettle,
  gymFeatureHydromassage: IconGymFeatureHydromassage,
  gymFeatureKeyFob: IconGymFeatureKeyFob,
  gymFeatureLadiesOnlyArea: IconGymFeatureLadiesOnlyArea,
  gymFeatureLargeFreeWeightsArea: IconGymFeatureLargeFreeWeightsArea,
  gymFeatureNewKit: IconGymFeatureNewKit,
  gymFeatureQualityKit: IconGymFeatureQualityKit,
  gymFeatureNewlyRefurbished: IconGymFeatureNewlyRefurbished,
  gymFeatureOpen24Hours: IconGymFeatureOpen24Hours,
  gymFeaturePTAvailable: IconGymFeaturePtAvailable,
  gymFeatureShowers: IconGymFeatureShowers,
  gymFeatureYanga: IconGymFeatureYanga,
  gymPackageFeatureAdvancedBooking: IconGymPackageFeatureAdvancedBooking,
  gymPackageFeatureBenefits: IconGymPackageFeatureBenefits,
  gymPackageFeatureBuddy: IconGymPackageFeatureBuddy,
  gymPackageFeatureMultiGym: IconGymPackageFeatureMultiGym,
  hand: IconHand,
  heart: IconHeart,
  'id-card': IconIdCard,
  info: IconInfo,
  instagram: IconInstagram,
  list: IconList,
  locale: IconLocale,
  lock: IconLock,
  'log-in': IconLogIn,
  'log-out': IconLogOut,
  map: IconMap,
  'map-pin': IconMapPin,
  menu: IconMenu,
  messenger: IconMessenger,
  'multi-gym': IconMultiGym,
  nfc: IconNfc,
  'no-contract': IconNoContract,
  phone: IconPhone,
  plus: IconPlus,
  qrCode: IconQrCode,
  search: IconSearch,
  snowflake: IconSnowflake,
  star: IconStar,
  'star-half': IconStarHalf,
  strava: IconStrava,
  target: IconTarget,
  'theme-dark': IconThemeDark,
  'theme-light': IconThemeLight,
  ticket: IconTicket,
  tiktok: IconTiktok,
  times: IconTimes,
  twitter: IconTwitter,
  unlock: IconUnlock,
  warning: IconWarning,
  weights: IconWeights,
  whatsapp: IconWhatsapp,
  wifi: IconWifi,
  youtube: IconYoutube,
};

const Icon = ({ name, ...rest }) => {
  const IconName = icons[name];

  return IconName ? <IconName {...rest} /> : null;
};

export { Icon };
