import styled from 'styled-components';
import { usePageState } from '~/context';
import { Blocks, BLOCK_TYPES, CATEGORY_THEME_COLORS } from '~/blocks';
import { PAGE_TYPES } from '~/pages/constants';

const ThemeBorder = styled.div`
  height: ${({ theme }) => theme.spacing.baseSpacing};
  background-color: ${({ categoryTheme }) => {
    switch (categoryTheme) {
      case CATEGORY_THEME_COLORS.BLUE:
        return '#1A4B72';
      case CATEGORY_THEME_COLORS.RED:
        return '#F1605A';
      case CATEGORY_THEME_COLORS.GREY:
        return '#9BCDC9';
      case CATEGORY_THEME_COLORS.BLACK:
        return '#0d0d0d';
      // no-default
    }
  }};

  ${({ theme }) => theme.mediaQueries.md} {
    height: ${({ theme }) => theme.spacing.doubleSpacing};
  }
`;

const ClassPage = () => {
  const { page } = usePageState();
  const { blocks, contentType, metadata } = page;

  // Add bgcolor to first singleColumnBlock
  const classIntroBlock = blocks.find(
    (block) => block.contentType === BLOCK_TYPES.SINGLE_COLUMN
  );

  if (contentType === PAGE_TYPES.CLASS_PAGE && classIntroBlock) {
    classIntroBlock.settings.applyThemeColor = true;
  }

  // Style first heading block
  const classHeadingBlock = blocks[0].content?.blocks.find(
    (block) => block.contentType === BLOCK_TYPES.HEADING
  );

  if (
    contentType === PAGE_TYPES.CLASS_CATEGORY_DETAILS_PAGE &&
    classHeadingBlock
  ) {
    classHeadingBlock.settings.applyThemeColor = true;
  }

  return (
    <>
      {contentType === PAGE_TYPES.CLASS_CATEGORY_DETAILS_PAGE && (
        <ThemeBorder categoryTheme={metadata?.theme} />
      )}
      <Blocks data={page.blocks} />
    </>
  );
};

export { ClassPage };
