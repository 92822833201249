import styled from 'styled-components';

const ASPECT_RATIOS = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
  SQUARE: 'square',
  WIDESCREEN: 'widescreen',
};

const IframeWrapper = styled.div`
  overflow: auto;
  width: 100%;

  iframe {
    display: block;
    margin: auto;
    border: none;
    width: 100%;
    max-width: ${({ maxWidth }) => maxWidth && `${maxWidth}px`};
    aspect-ratio: ${({ aspectRatio }) => {
      switch (aspectRatio) {
        case ASPECT_RATIOS.LANDSCAPE:
          return '1.41 / 1';
        case ASPECT_RATIOS.PORTRAIT:
          return '1 / 1.41';
        case ASPECT_RATIOS.SQUARE:
          return '1 / 1';
        case ASPECT_RATIOS.WIDESCREEN:
          return '16 / 9';
        default:
          return '1 / 1';
      }
    }};
  }
`;

const IframeBlock = ({ data: { content, key, settings } }) => (
  <IframeWrapper
    maxWidth={settings?.maxWidth ? settings?.maxWidth : null}
    aspectRatio={settings?.aspectRatio}
  >
    <iframe
      data-testid={key}
      src={content.sourceUrl?.url}
      title={content.sourceUrl?.name}
    ></iframe>
  </IframeWrapper>
);

export { IframeBlock };
