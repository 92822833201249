import styled from 'styled-components';
import { Button, Heading, HEADING_VARIANTS } from '@puregym/ui';

import { Icon } from '~/ui';

const LinkContainer = styled.div`
  ${({ theme }) => theme.mediaQueries.md} {
    aspect-ratio: 1 / 1;

    @supports not (aspect-ratio: 1 / 1) {
      &::before {
        content: '';
        float: left;
        padding-top: 100%;
      }

      &::after {
        content: '';
        display: block;
        clear: both;
      }
    }
  }
`;

const CategoryLink = styled.a`
  display: block;
  padding: ${({ theme }) => theme.spacing.halfSpacing};
  text-decoration: none;

  border: ${({ theme }) =>
    `1px solid ${theme.colors.opacities.invertedSubtle}`};
  border-radius: ${({ theme }) => theme.radii.default};
  background-color: ${({ theme }) => theme.colors.background};

  ${({ theme }) => theme.mediaQueries.md} {
    display: grid;
    grid-template-areas: 'stack';
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.background};
    box-shadow: ${({ theme }) => theme.boxShadows.default};

    &:hover,
    &:focus,
    &:active {
      background-color: ${({ theme }) => theme.colors.accents.dark};
      color: ${({ theme }) => theme.colors.contrastText};
    }

    > * {
      grid-area: stack;
    }
  }
`;

const CategoryLinkPanel = styled.div`
  display: flex;
  align-items: center;

  ${Heading} {
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.text};
  }

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: ${({ theme }) => theme.spacing.baseSpacing};
    padding: ${({ theme }) => theme.spacing.baseSpacing};
    font-size: ${({ theme }) => theme.fontSizes.xsmall};

    ${CategoryLink}:hover &,
    ${CategoryLink}:focus &,
    ${CategoryLink}:active & {
      opacity: 0;
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    gap: ${({ theme }) => theme.spacing.doubleSpacing};
    padding: ${({ theme }) => theme.spacing.doubleSpacing};
    font-size: ${({ theme }) => theme.fontSizes.standard};
  }
`;

const CategoryLinkSubPanel = styled(CategoryLinkPanel)`
  color: ${({ theme }) => theme.colors.text};

  ${Button} {
    display: none;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    opacity: 0;
    line-height: 1.1;
    color: ${({ theme }) => theme.colors.contrastText};

    ${CategoryLink}:hover &,
    ${CategoryLink}:focus &,
    ${CategoryLink}:active & {
      opacity: 1;
    }
  }
`;

const IconContainer = styled.div`
  margin-inline-end: ${({ theme }) => theme.spacing.halfSpacing};
  font-size: 4rem;
  line-height: 1;
  color: ${({ theme }) => theme.colors.primary.main};

  ${({ theme }) => theme.mediaQueries.lg} {
    font-size: 6rem;
  }
`;

const CategoryLinkBlock = ({ data: { content } }) => {
  const { buttonText, icon, name, subHeading } = content;

  return (
    <LinkContainer>
      <CategoryLink href={content.href}>
        <CategoryLinkPanel>
          <IconContainer>
            <Icon name={icon} aria-hidden={true} />
          </IconContainer>
          <Heading as="h3" variant={HEADING_VARIANTS.DELTA}>
            {name}
          </Heading>
        </CategoryLinkPanel>
        <CategoryLinkSubPanel>
          {subHeading}
          <Button as="div">{buttonText}</Button>
        </CategoryLinkSubPanel>
      </CategoryLink>
    </LinkContainer>
  );
};

export { CategoryLinkBlock };
