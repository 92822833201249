import styled from 'styled-components';

import { Button, BUTTON_VARIANTS, Grid } from '@puregym/ui';

const QuestionLinkButton = styled(Button)`
  padding: ${({ theme }) => theme.spacing.doubleSpacing};
  justify-content: center;
  text-align: center;
`;

const QuestionLinkBlock = ({ data: { content } }) => {
  const { href, text } = content;

  return (
    <QuestionLinkButton as="a" href={href} variant={BUTTON_VARIANTS.SUBDUED}>
      {text}
    </QuestionLinkButton>
  );
};

const QuestionLinkListBlock = ({ data: { content } }) => {
  return (
    <Grid minItemWidth="240px">
      {content.blocks.map((category) => (
        <QuestionLinkBlock data={category} key={category.key} />
      ))}
    </Grid>
  );
};

export { QuestionLinkBlock, QuestionLinkListBlock };
