import styled from 'styled-components';
import {
  BUTTON_VARIANTS,
  Button,
  Grid,
  HEADING_VARIANTS,
  Heading,
} from '@puregym/ui';

import { usePageState } from '~/context';
import { IMAGE_EAGER_LOAD_LIMIT, IMAGE_LOADING_BEHAVIOR } from '~/constants';
import { ClassBenefitListBlock } from './index';

const ClassImageWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const ClassImage = styled.img`
  display: block;
  transition: ${({ theme }) => theme.transitionsMs.default};
`;

const Benefits = styled.div`
  position: relative;
  margin-top: ${({ theme }) =>
    `-${theme.BASE_SPACING * 3}px`}; // half icon + gap
`;

const RelatedClass = styled.div`
  position: relative;
  width: 270px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.baseSpacing};
  text-align: center;
  text-decoration: none;
  border-radius: ${({ theme }) => theme.radii.default};

  &:hover,
  &:focus,
  &:active {
    ${ClassImage} {
      transform: scale(1.05);
    }
  }
`;

const RelatedClassBlock = ({ data, index }) => {
  const { labels } = usePageState();
  const { benefits, name, thumbnailImage, url } = data;

  const title = thumbnailImage?.title;
  const imgSrc = thumbnailImage?.src;
  const loadingBehavior =
    index <= IMAGE_EAGER_LOAD_LIMIT
      ? IMAGE_LOADING_BEHAVIOR.EAGER
      : IMAGE_LOADING_BEHAVIOR.LAZY;

  let resizedImgSrc;
  let srcSet;
  const imgDisplayHeight = 300;
  const imgDisplayWidth = 270;

  if (imgSrc) {
    const sharedParams = '&mode=crop&anchor=left';
    const params = `height=${imgDisplayHeight}&width=${imgDisplayWidth}${sharedParams}`;
    const highResParams = `height=${imgDisplayHeight * 2}&width=${
      imgDisplayWidth * 2
    }${sharedParams}`;

    resizedImgSrc = imgSrc.includes('?')
      ? `${imgSrc}&${params}`
      : `${imgSrc}?${params}`;

    const highResSrc = imgSrc.includes('?')
      ? `${imgSrc}&${highResParams}`
      : `${imgSrc}?${highResParams}`;

    srcSet = `${resizedImgSrc} 1x, ${highResSrc} 2x`;
  }

  return (
    <RelatedClass>
      <Heading as="h4" variant={HEADING_VARIANTS.DELTA} style={{ margin: 0 }}>
        {name}
      </Heading>
      {imgSrc && (
        <ClassImageWrapper>
          <ClassImage
            loading={loadingBehavior}
            src={resizedImgSrc}
            srcSet={srcSet}
            title={title}
            height={imgDisplayHeight}
            width={imgDisplayWidth}
          />
        </ClassImageWrapper>
      )}
      {benefits.length && (
        <Benefits>
          <ClassBenefitListBlock
            data={{
              content: { benefits },
              settings: {
                size: 'small',
                variant: 'filled',
                showShortTitle: true,
              },
            }}
          />
        </Benefits>
      )}
      <Button
        as="a"
        href={url}
        $stretch={true}
        variant={BUTTON_VARIANTS.OUTLINE}
      >
        {labels.core.view}
      </Button>
    </RelatedClass>
  );
};

const RelatedClassesListBlock = ({ data: { content, key } }) => (
  <Grid minItemWidth="260px">
    {content.relatedClasses.map((relatedClass, index) => (
      <RelatedClassBlock
        data={relatedClass}
        key={`${key}-${relatedClass.name}`}
        index={index}
      />
    ))}
  </Grid>
);

export { RelatedClassesListBlock };
