import { VIDEO_TYPES } from '~/constants';

/**
 * Returns the URL for a given video ID and type, with optional query params
 * added if being shown in the background and if is autoplay
 *
 * @param {string} videoId
 * @param {string} videoType YouTube or Vimeo
 * @param {boolean} isBgVideo whether this is being shown in the background
 * @param {boolean} autoplay whether the video should autoplay
 * @returns {string}
 */
const getVideoUrl = (
  videoId,
  videoType,
  isBgVideo = false,
  autoplay = false
) => {
  const encodedId = encodeURIComponent(videoId);

  switch (videoType) {
    case VIDEO_TYPES.YOUTUBE:
      /**
       * We need the playlist param in order for the loop param to work
       * (and they must be in this order) ¯\_(ツ)_/¯
       * @see https://developers.google.com/youtube/player_parameters
       */
      return getYoutubeUrl(encodedId, isBgVideo, autoplay);
    case VIDEO_TYPES.VIMEO:
      /**
       * This feature (passing background param) requires a pro Vimeo account
       * @see https://help.vimeo.com/hc/en-us/articles/12426285089681-Embed-background-and-chromeless-videos
       */
      return getVimeoUrl(encodedId, isBgVideo, autoplay);
    // no default
  }
};

const getYoutubeUrl = (encodedId, isBgVideo, autoplay) => {
  let videoUrl = `https://www.youtube-nocookie.com/embed/${encodedId}`;
  if (isBgVideo) {
    return (videoUrl += `?rel=0&playsinline=1&autohide=1&mute=1&showinfo=0&controls=0&autoplay=1&playlist=${encodedId}&loop=1`);
  }

  return autoplay ? (videoUrl += '?autoplay=1&mute=1') : videoUrl;
};

const getVimeoUrl = (encodedId, isBgVideo, autoplay) => {
  let videoUrl = `https://player.vimeo.com/video/${encodedId}`;
  if (isBgVideo) {
    return (videoUrl += '?background=1');
  }

  return autoplay ? (videoUrl += '?autoplay=1&muted=1&loop=1') : videoUrl;
};

export { getVideoUrl };
