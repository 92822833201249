import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { usePageState } from '~/context';
const TrustpilotBlock = ({ data: { content } }) => {
  const { url, businessUnitId } = content;
  const { global } = usePageState();

  const currentCultureCode = global.languages?.currentCulture?.cultureCode;
  const currentLanguageCode = global.languages?.currentCulture?.languageCode;

  const TrustPilotWrapper = styled.div`
    width: 100%;
  `;

  return (
    <>
      {url && businessUnitId && (
        <>
          <Helmet>
            <script
              type="text/javascript"
              src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
              async
            ></script>
          </Helmet>
          <TrustPilotWrapper>
            <div
              className="trustpilot-widget"
              data-locale={currentCultureCode}
              data-template-id="54ad5defc6454f065c28af8b"
              data-businessunit-id={businessUnitId}
              data-style-height="240px"
              data-style-width="100%"
              data-theme="light"
              data-stars="4,5"
              data-review-languages={currentLanguageCode}
            >
              <a href={url} target="_blank" rel="noreferrer">
                Trustpilot
              </a>
            </div>
          </TrustPilotWrapper>
        </>
      )}
    </>
  );
};

export { TrustpilotBlock };
