import styled from 'styled-components';
import { getVideoUrl } from '~/utils';

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  iframe {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 177.78vh; /* 100 * 16 / 9 */
    height: 100%;
    min-width: 100%;
    min-height: 56.25vw; /* 100 * 9 / 16 */
    border: none;
  }
`;

const BackgroundVideo = ({ videoId, videoType, videoTitle }) => (
  <VideoWrapper>
    <iframe
      src={getVideoUrl(videoId, videoType, true)}
      frameBorder="0"
      allow="autoplay; encrypted-media; fullscreen; picture-in-picture"
      data-testid={`bg-video-${videoId}`}
      title={videoTitle}
    ></iframe>
  </VideoWrapper>
);

export { BackgroundVideo };
