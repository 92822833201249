import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { COOKIES } from '@puregym/ui';
import { CONSENT_TYPES, getUserConsent } from '@puregym/cookieconsent';

import { usePageState } from '~/context';
import { Blocks } from '~/blocks';

const StandardPage = () => {
  const { global, page } = usePageState();
  const isStudentContent = page.metadata?.isStudentContent;
  const trackingPermissionRequired = global.trackingPermissionRequired;

  useEffect(() => {
    if (
      isStudentContent &&
      (!trackingPermissionRequired || getUserConsent(CONSENT_TYPES.FUNCTIONAL))
    ) {
      Cookies.set(COOKIES.STUDENT_PROMO, 'iframe');
      Cookies.set(COOKIES.STUDENT_VISIT, 'true');
    }
  }, [isStudentContent, trackingPermissionRequired]);

  return <Blocks data={page.blocks} />;
};

export { StandardPage };
