import {
  Arrow,
  Card,
  Heading,
  HEADING_VARIANTS,
  List,
  ListItem,
  LIST_VARIANTS,
} from '@puregym/ui';

import { usePageState } from '~/context';

const BlogSecondaryCategoryLinkListBlock = ({ categories, heading }) => {
  const { global } = usePageState();
  const isRtl = global.languages.currentCulture?.rtl;

  return (
    <Card>
      <Heading variant={HEADING_VARIANTS.BETA}>{heading}</Heading>
      <List variant={LIST_VARIANTS.SIMPLE}>
        {categories.map((cat) => (
          <ListItem key={cat.key}>
            <a href={cat.url}>
              {cat.name}{' '}
              <Arrow
                arrowDirection="forward"
                isRtl={isRtl}
                aria-hidden={true}
              />
            </a>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export { BlogSecondaryCategoryLinkListBlock };
