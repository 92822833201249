import { Grid } from '@puregym/ui';

import { CardBlock } from './cardBlock';

const CardListBlock = ({ data: { content } }) => (
  <Grid minItemWidth="260px">
    {content.blocks.map((data) => (
      <CardBlock data={data} key={data.key} />
    ))}
  </Grid>
);

export { CardListBlock };
