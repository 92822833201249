import { Grid } from '@puregym/ui';
import styled from 'styled-components';
import { CategoryLinkBlock } from './categoryLinkBlock';

const CategoryListGrid = styled(Grid)`
  grid-template-columns: repeat(auto-fit, 100%);

  ${({ theme }) => theme.mediaQueries.md} {
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(180px, 18%));
  }
`;

const CategoryLinkListBlock = ({ data: { content } }) => (
  <CategoryListGrid>
    {content.blocks.map((category) => (
      <CategoryLinkBlock data={category} key={category.key} />
    ))}
  </CategoryListGrid>
);

export { CategoryLinkListBlock };
