import styled from 'styled-components';
import { Button, BUTTON_VARIANTS } from '@puregym/ui';
import { Icon } from './icon';

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.baseSpacing};

  ${({ theme }) => theme.mediaQueries.md} {
    gap: ${({ theme }) => theme.spacing.threeQuarterSpacing};
  }
`;

const SocialShareButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ platform }) => {
    switch (platform) {
      case 'facebook':
        return '#3B5998';
      case 'twitter':
        return '#1DA1F2';
      // no default
    }
  }};

  span {
    white-space: nowrap;
    overflow: hidden;
  }
`;

const SocialShareLink = ({ data, platform }) => {
  const { url, name } = data;

  return (
    <SocialShareButton
      as="a"
      href={url}
      target="_blank"
      rel="noopener"
      variant={BUTTON_VARIANTS.OUTLINE}
      platform={platform}
    >
      <Icon name={platform} />
      <span>{name}</span>
    </SocialShareButton>
  );
};

const SocialShareLinks = ({ links }) => (
  <LinksWrapper>
    {links?.map((link) => (
      <SocialShareLink
        data={link.shareUrl}
        platform={link.slug}
        key={link.key}
      />
    ))}
  </LinksWrapper>
);

export { SocialShareLinks, SocialShareLink };
