import { useState } from 'react';
import { Helmet } from 'react-helmet';
import styled, { css, createGlobalStyle } from 'styled-components';
import { cloneDeep } from 'lodash-es';

import {
  Button,
  BUTTON_VARIANTS,
  Heading,
  HEADING_VARIANTS,
  Modal,
  useTheme,
} from '@puregym/ui';

import { usePageState } from '~/context';
import { BackgroundVideo, RichText } from '~/ui';
import { getLinkTarget } from '~/utils/urls';
import { VERTICAL_ALIGNMENTS } from './constants';
import { VideoBlock } from './videoBlock';

const MAX_IMG_WIDTH = 2500;

/**
 * @todo: update Modal component in Pure UI to allow for this styling
 */
const ModalStyles = createGlobalStyle`
  .ReactModal__Content { width: 100%; }
  .ReactModal__Overlay { background-color: rgba(0, 0, 0, 0.8) !important; }
`;

/**
 * Wrapper sets a grid for the Hero block.
 *
 * If `overlap` is true, a negative margin pulls the block beneath
 * up to overlap (used on homepage).
 *
 * For `fullHeight`, calculate 100vh minus the height of the header (logo + padding)
 */
const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.accents.dark};

  ${({ hasBgVideo }) => {
    return hasBgVideo
      ? css`
          aspect-ratio: 16 / 9;
          max-height: 85vh;
        `
      : css`
          min-height: 70vh;
        `;
  }}

  ${({ fullHeight, theme }) => {
    return (
      fullHeight &&
      css`
        min-height: calc(100vh - ${50 + theme.BASE_SPACING * 2}px);
        max-height: none;
      `
    );
  }}

  ${({ overlap, theme }) => {
    return (
      overlap &&
      css`
        margin-bottom: -${theme.BASE_SPACING * 4}px;
      `
    );
  }}

  ${({ backgroundImage, theme }) =>
    backgroundImage &&
    css`
      background: top / cover no-repeat ${theme.colors.accents.dark}
        url(${backgroundImage.srcSet?.mobile || backgroundImage.src});
    `}

  ${({ theme }) => theme.mediaQueries.md} {
    ${({ backgroundImage }) =>
      backgroundImage &&
      css`
        background-image: url(${backgroundImage.srcSet?.tablet ||
        backgroundImage.src});
      `}

    ${({ overlap, theme }) => {
      return (
        overlap &&
        css`
          margin-bottom: -${theme.BASE_SPACING * 8}px;
        `
      );
    }}
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    ${({ backgroundImage }) =>
      backgroundImage &&
      css`
        background-image: url(${backgroundImage.srcSet?.desktop ||
        backgroundImage.src});
      `}

    ${({ overlap, theme }) =>
      overlap &&
      css`
        margin-bottom: -${theme.BASE_SPACING * 10}px;
      `}
  }

  /* If text is placed at the end and overlap is true, increase bottom padding */
  ${({ overlap, textPlacement }) => {
    return (
      overlap &&
      textPlacement === VERTICAL_ALIGNMENTS.END &&
      css`
        padding-bottom: ${({ theme }) =>
          `${theme.BASE_SPACING * 8}px !important`};
      `
    );
  }}
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  align-self: stretch;
  padding: ${({ theme }) => theme.spacing.doubleSpacing};
  padding-bottom: ${({ overlap, theme }) =>
    overlap ? `${theme.BASE_SPACING * 4}px` : theme.spacing.doubleSpacing};

  gap: ${({ theme }) => theme.spacing.baseSpacing};
  z-index: 1;
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.contrastText};

  &:hover {
    color: ${({ theme }) => theme.colors.contrastText};
  }

  justify-content: ${({ textPlacement }) => {
    switch (textPlacement) {
      case VERTICAL_ALIGNMENTS.START:
        return 'start';
      case VERTICAL_ALIGNMENTS.CENTER:
        return 'center';
      case VERTICAL_ALIGNMENTS.END:
        return 'end';
      default:
        return 'center';
    }
  }};

  ${({ theme }) => theme.mediaQueries.md} {
    padding: ${({ theme }) =>
      `${theme.BASE_SPACING * 4}px ${theme.spacing.doubleSpacing}`};
  }
`;

const HeadingContainer = styled.div`
  ${({ theme, showDivider }) => {
    return (
      showDivider &&
      css`
        border-bottom: 1px solid ${theme.colors.accents.muted};
        padding-bottom: ${theme.spacing.baseSpacing};
      `
    );
  }}
`;

const HeroHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.contrastText};
  line-height: 1.2;

  &:only-child {
    margin: 0;
  }

  > span {
    display: inline-block;
    padding: ${({ theme }) =>
      `${theme.spacing.quarterSpacing} ${theme.spacing.baseSpacing}`};
    background-color: ${({ theme }) => theme.colors.secondary.main};
  }
`;

const SubHeading = styled(Heading)`
  display: block;
  margin-block-end: ${({ theme }) => theme.spacing.halfSpacing};
  text-align: center;
  color: ${({ theme }) => theme.colors.contrastText};
`;

const HeroPreload = ({ backgroundImage }) => {
  const { mediaQueries } = useTheme();
  const { src, srcSet } = backgroundImage;

  return (
    <>
      {src && (
        <Helmet>
          <link rel="preload" href={src} as="image" />
        </Helmet>
      )}
      {srcSet && (
        <Helmet>
          <link
            rel="preload"
            href={srcSet.mobile}
            as="image"
            media={mediaQueries.smMax}
          />
          <link
            rel="preload"
            href={srcSet.tablet}
            as="image"
            media={mediaQueries.mdMax}
          />
          <link
            rel="preload"
            href={srcSet.desktop}
            as="image"
            media={mediaQueries.lg}
          />
        </Helmet>
      )}
    </>
  );
};

const HeroBlock = ({ data }) => {
  const { labels } = usePageState();
  const [isOpen, setIsOpen] = useState(false);

  const {
    additionalInformation,
    backgroundImage,
    backgroundVideoId,
    backgroundVideoType,
    backgroundVideoTitle,
    backgroundVideoButtonText,
    heading,
    link,
    subheadingLine1,
    subheadingLine2,
    title,
  } = data.content;

  const name = link?.name;
  const url = link?.url;
  const fullHeight = data.settings?.fullHeight;
  const overlap = data.settings?.overlap;
  const textPlacement = data.settings?.textPlacement;

  const onModalButtonClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const hasBgVideo =
    backgroundVideoId && backgroundVideoType && backgroundVideoTitle;

  let resizedBgImage = cloneDeep(backgroundImage);

  if (backgroundImage?.src) {
    resizedBgImage.src += `&width=${MAX_IMG_WIDTH}`;
  }

  if (backgroundImage?.srcSet?.desktop) {
    resizedBgImage.srcSet.desktop += `&width=${MAX_IMG_WIDTH}`;
  }

  return (
    <>
      {backgroundImage && <HeroPreload backgroundImage={resizedBgImage} />}
      <ModalStyles />
      <Wrapper
        backgroundImage={resizedBgImage}
        fullHeight={fullHeight}
        hasBgVideo={hasBgVideo}
        overlap={overlap}
      >
        <TextContainer
          as={url ? 'a' : 'div'}
          data-linktarget={getLinkTarget(url)}
          href={url}
          overlap={overlap}
          textPlacement={textPlacement}
          title={name}
        >
          <HeadingContainer
            showDivider={
              (additionalInformation?.text ||
                (hasBgVideo && backgroundVideoButtonText)) &&
              (subheadingLine1 || subheadingLine2)
            }
          >
            {(title || heading) && (
              <HeroHeading>
                <span>{title || heading}</span>
              </HeroHeading>
            )}
            {subheadingLine1 && (
              <SubHeading as="span" variant={HEADING_VARIANTS.GAMMA}>
                {subheadingLine1}
              </SubHeading>
            )}
            {subheadingLine2 && (
              <SubHeading as="span" variant={HEADING_VARIANTS.GAMMA}>
                {subheadingLine2}
              </SubHeading>
            )}
          </HeadingContainer>
          {additionalInformation && <RichText data={additionalInformation} />}
          {hasBgVideo && backgroundVideoButtonText && (
            <Button
              onClick={onModalButtonClick}
              variant={BUTTON_VARIANTS.OUTLINE}
            >
              {backgroundVideoButtonText}
            </Button>
          )}
        </TextContainer>
        {hasBgVideo && (
          <BackgroundVideo
            videoId={backgroundVideoId}
            videoType={backgroundVideoType}
            videoTitle={backgroundVideoTitle}
          />
        )}
      </Wrapper>
      {hasBgVideo && (
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          headingText={backgroundVideoTitle}
          closeText={labels.core.closeMenuButton}
          modalWide={true}
        >
          <VideoBlock
            data={{
              content: {
                description: backgroundVideoTitle,
                title: backgroundVideoTitle,
                videoId: backgroundVideoId,
                videoType: backgroundVideoType,
              },
            }}
          />
        </Modal>
      )}
    </>
  );
};

export { HeroBlock };
