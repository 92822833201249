import styled, { css } from 'styled-components';

import { BLOCK_SIZES } from './constants';
import { Wrapper } from '~/ui';

const defaultBreakpoints = {
  mobile: '600w',
  tablet: '1800w',
  desktop: '2500w',
};

const ImageLink = styled.a`
  align-self: normal;
`;

const Image = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  border-radius: ${({ theme }) => theme.radii.default};
  aspect-ratio: ${({ width, height }) =>
    width && height ? `${width} / ${height}` : 'auto'};

  ${({ size }) => {
    switch (size) {
      case BLOCK_SIZES.SMALL:
        return css`
          max-width: 50%;
        `;
      case BLOCK_SIZES.MEDIUM:
        return css`
          max-width: 80%;
        `;
      case BLOCK_SIZES.LARGE:
        return css`
          width: 100%;
        `;
      // no default
    }
  }};
`;

const ImageBlock = ({
  data: { content, settings },
  breakpoints = defaultBreakpoints,
}) => {
  const { alt, height, link, src, srcSet, width } = content;
  const anchorID = settings?.anchorID?.trim?.();
  const imageSize = settings?.imageSize;
  const lazyLoad = settings?.lazyLoad;
  const isResponsive =
    srcSet && (srcSet.mobile || srcSet.tablet || srcSet.desktop);

  /** We should never receive img data with no source, but if we do then return nothing */
  if (!src) return null;

  /**
   * We set width + height to allow browsers to know the
   * aspect ratio. Actual width is governed by the
   * container, height is set to auto in CSS.
   */
  return (
    <Wrapper
      condition={link}
      wrapper={(children) => (
        <ImageLink href={link.url} title={link.name} target={link.target}>
          {children}
        </ImageLink>
      )}
    >
      <Image
        id={anchorID ? anchorID : undefined}
        alt={alt}
        loading={lazyLoad ? 'lazy' : 'eager'}
        size={imageSize}
        height={height ? height : null}
        width={width ? width : null}
        src={src}
        srcSet={
          isResponsive &&
          `${srcSet.desktop} ${breakpoints.desktop}, ${srcSet.tablet} ${breakpoints.tablet}, ${srcSet.mobile} ${breakpoints.mobile}`
        }
      />
    </Wrapper>
  );
};

export { ImageBlock };
