import styled from 'styled-components';
import { Grid } from '@puregym/ui';

import { BlogPostLinkBlock } from './blogPostLinkBlock';

const StyledGrid = styled(Grid)`
  ${({ theme }) => theme.mediaQueries.lgMax} {
    gap: ${({ theme }) => theme.spacing.baseSpacing};
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    grid-template-columns: ${({ minItemWidth, theme }) =>
      `repeat(auto-fit, minmax(${minItemWidth}, calc(33.3% - ${
        theme.BASE_SPACING * 1.5
      }px)))`};
  }
`;

const BlogPrimaryCategoryLinkListBlock = ({ data }) => (
  <StyledGrid minItemWidth="240px">
    {data.map((blogLink) => (
      <BlogPostLinkBlock data={blogLink} key={blogLink.key} />
    ))}
  </StyledGrid>
);

export { BlogPrimaryCategoryLinkListBlock };
