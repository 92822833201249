import styled from 'styled-components';

import { Form, Text, Button, Fieldset, InputField } from '@puregym/ui';
import { validateLiveChatParticipantDetails } from './chatUserValidation';

const ChatUserWrapper = styled.div`
  @media (min-width: 768px) {
    max-width: 40%;
  }
`;

const ChatUser = ({ resources, onComplete }) => {
  const validateChatUser = (e) => {
    e.preventDefault();

    const validationResult = validateLiveChatParticipantDetails(e.target);
    const hasErrors = validationResult.validationErrors?.length !== 0;
    if (!hasErrors) {
      onComplete(validationResult.name, validationResult.email);
    }
  };

  return (
    <ChatUserWrapper>
      <Text>{resources.notLoggedInWelcome}</Text>
      <Form onSubmit={validateChatUser}>
        <Fieldset aria-label="Chat User Form">
          <InputField
            name="chatUserName"
            label={resources.nameLabel}
            required={true}
          />
          <InputField
            name="chatUserEmail"
            label={resources.emailLabel}
            required={true}
          />
        </Fieldset>
        <Button>{resources.beginChatButtonText}</Button>
      </Form>
    </ChatUserWrapper>
  );
};

export default ChatUser;
