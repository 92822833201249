import styled, { css } from 'styled-components';
import { usePageState } from '~/context';
import { Icon } from '~/ui';
import { CATEGORY_THEME_COLORS } from '~/blocks';

const ClassBenefitList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: start;
  text-align: center;

  gap: ${({ theme, size }) => {
    switch (size) {
      case 'medium':
        return theme.spacing.baseSpacing;
      case 'small':
        return theme.spacing.halfSpacing;
      // no default
    }
  }};

  li {
    list-style: none;
    display: flex;
    flex-direction: column;
    flex-basis: 120px;
  }
`;

const StyledClassBenefit = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme, size }) => {
    switch (size) {
      case 'medium':
        return theme.spacing.baseSpacing;
      case 'small':
        return theme.spacing.halfSpacing;
      // no default
    }
  }};

  color: ${({ categoryTheme }) => {
    switch (categoryTheme) {
      case CATEGORY_THEME_COLORS.BLUE:
        return '#1A4B72';
      case CATEGORY_THEME_COLORS.RED:
        return '#F1605A';
      case CATEGORY_THEME_COLORS.GREY:
        return '#9BCDC9';
      case CATEGORY_THEME_COLORS.BLACK:
        return '#0d0d0d';
      // no-default
    }
  }};
`;

const FilledVariantStyles = css`
  background-color: ${({ theme }) => theme.colors.background};
  border-color: ${({ theme }) => theme.colors.opacities.invertedStrong};

  color: ${({ categoryTheme, theme }) => {
    switch (categoryTheme) {
      case CATEGORY_THEME_COLORS.BLUE:
        return '#1A4B72';
      case CATEGORY_THEME_COLORS.RED:
        return '#F1605A';
      case CATEGORY_THEME_COLORS.GREY:
        return '#9BCDC9';
      case CATEGORY_THEME_COLORS.BLACK:
        return '#0d0d0d';
      default:
        return theme.colors.text;
    }
  }};
`;

const IconWrapper = styled.div`
  display: inline-block;
  align-self: center;
  border: ${({ theme }) => `1px solid ${theme.colors.accents.muted}`};
  border-radius: 500px;

  ${({ variant }) => variant === 'filled' && FilledVariantStyles};

  padding: ${({ theme, size }) => {
    switch (size) {
      case 'medium':
        return theme.spacing.baseSpacing;
      case 'small':
        return theme.spacing.halfSpacing;
      // no default
    }
  }};

  padding: ${({ theme, size }) => {
    switch (size) {
      case 'medium':
        return theme.spacing.baseSpacing;
      case 'small':
        return theme.spacing.halfSpacing;
      // no default
    }
  }};

  svg {
    font-size: ${({ size }) => {
      switch (size) {
        case 'medium':
          return '5rem';
        case 'small':
          return '3rem';
        // no default
      }
    }};
  }
`;

const BenefitName = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.1;
  font-size: ${({ theme, size }) => {
    switch (size) {
      case 'medium':
        return theme.fontSizes.medium;
      case 'small':
        return theme.fontSizes.xxsmall;
      // no default
    }
  }};
`;

const ClassBenefitIcon = ({
  benefit,
  categoryTheme,
  displayBenefitName = true,
  showShortTitle = false,
  size = 'medium',
  useThemeColors = true,
  variant = 'outline',
}) => {
  const { icon, shortTitle, title } = benefit;

  return (
    <StyledClassBenefit
      size={size}
      categoryTheme={useThemeColors && categoryTheme}
    >
      <IconWrapper
        size={size}
        variant={variant}
        categoryTheme={useThemeColors && categoryTheme}
      >
        <Icon name={icon} aria-hidden={true} />
      </IconWrapper>
      {displayBenefitName && (
        <BenefitName size={size}>
          {showShortTitle ? shortTitle : title}
        </BenefitName>
      )}
    </StyledClassBenefit>
  );
};

const ClassBenefitListBlock = ({ data: { content, key, settings } }) => {
  const { page } = usePageState();

  if (!content.benefits?.length) return null;

  const size = settings?.size || 'medium';
  const variant = settings?.variant || 'outline';
  const showShortTitle = settings?.showShortTitle;
  const displayBenefitName = settings?.displayBenefitName;
  const useThemeColors = settings?.useThemeColors;

  return (
    <ClassBenefitList size={size}>
      {content.benefits.map((benefit) => (
        <li key={`${key}-${benefit.shortTitle}`}>
          <ClassBenefitIcon
            benefit={benefit}
            categoryTheme={page.metadata?.theme}
            displayBenefitName={displayBenefitName}
            showShortTitle={showShortTitle}
            size={size}
            useThemeColors={useThemeColors}
            variant={variant}
          />
        </li>
      ))}
    </ClassBenefitList>
  );
};
export { ClassBenefitListBlock, ClassBenefitIcon };
