import styled from 'styled-components';
import {
  Heading,
  HEADING_VARIANTS,
  List,
  ListItem,
  LIST_VARIANTS,
} from '@puregym/ui';

const NavWrapper = styled.nav`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing.baseSpacing};
  margin-bottom: ${({ theme }) => theme.spacing.baseSpacing};
  padding: ${({ theme }) => `${theme.spacing.baseSpacing} 0`};

  ${({ theme }) => theme.mediaQueries.sm} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  }

  @media print {
    display: none;
  }
`;

const NavLink = styled.a`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
`;

const FooterNav = ({ navData }) => {
  if (!navData) return null;

  return (
    <NavWrapper>
      {navData.map((item, i) => {
        const itemName = item.name?.toLowerCase().replace(/\s/g, '-');
        const sectionId = `${itemName}-${i}`;

        return (
          <section key={sectionId}>
            <Heading
              as="h5"
              id={sectionId}
              variant={HEADING_VARIANTS.EPSILON}
              css={`
                margin-bottom: ${({ theme }) => theme.spacing.baseSpacing};
                color: ${({ theme }) => theme.colors.text};
              `}
            >
              {item.name}
            </Heading>
            {item.navigationItems && (
              <List aria-labelledby={sectionId} variant={LIST_VARIANTS.PLAIN}>
                {item.navigationItems.map(
                  (subItem, j) =>
                    subItem.to && (
                      <ListItem key={`${subItem}${j}`}>
                        <NavLink href={subItem.to}>{subItem.name}</NavLink>
                      </ListItem>
                    )
                )}
              </List>
            )}
          </section>
        );
      })}
    </NavWrapper>
  );
};

export { FooterNav };
