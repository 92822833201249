import styled from 'styled-components';
import {
  Button,
  BUTTON_VARIANTS,
  IconAccountCircle,
  IconLocale,
} from '@puregym/ui';

import { usePageState } from '~/context';

const MenuWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.halfSpacing};

  [data-hidemobile] {
    display: none;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    [data-hidemobile] {
      display: inline-flex;
    }
  }
`;

const UserMenu = ({ showLanguageLink }) => {
  const { global, labels } = usePageState();
  const isLoggedIn = global.user?.loggedIn;
  const isLanguageSelectorVisible = global.layout?.languageSelectorVisible;
  const contentRootUrl =
    global.languages?.currentCulture?.contentRootUrl || '/';

  return (
    <MenuWrapper>
      {isLanguageSelectorVisible && showLanguageLink && (
        <Button
          as="a"
          href="#language-switcher"
          variant={BUTTON_VARIANTS.PLAIN}
        >
          <IconLocale title={labels.core.languageSelectorTitle} />
        </Button>
      )}
      {isLoggedIn ? (
        <Button
          as="a"
          href={`${contentRootUrl}logout`}
          variant={BUTTON_VARIANTS.PLAIN}
        >
          <IconAccountCircle title={labels.core.logOutButton} />
          <span data-hidemobile aria-hidden>
            {labels.core.logOutButton}
          </span>
        </Button>
      ) : (
        <Button
          as="a"
          href={`${contentRootUrl}login`}
          variant={BUTTON_VARIANTS.PLAIN}
        >
          <IconAccountCircle title={labels.core.logInButton} />
          <span data-hidemobile aria-hidden>
            {labels.core.logInButton}
          </span>
        </Button>
      )}
    </MenuWrapper>
  );
};

export { UserMenu };
