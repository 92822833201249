import styled from 'styled-components';
import { lighten } from 'polished';
import {
  Button,
  Label,
  maxWidthLayoutStyles,
  visuallyHiddenStyles,
} from '@puregym/ui';

const Wrapper = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing.halfSpacing} ${theme.spacing.baseSpacing}`};
  background-color: ${({ theme }) => lighten(0.1, theme.colors.accents.dark)};
  color: ${({ theme }) => theme.colors.contrastText};
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${maxWidthLayoutStyles}
`;

const RegionSwitcherForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  row-gap: ${({ theme }) => theme.spacing.halfSpacing};

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
    column-gap: ${({ theme }) => theme.spacing.halfSpacing};
    margin-inline-end: ${({ theme }) => theme.spacing.baseSpacing};
  }
`;

const LabelText = styled(Label)`
  flex-grow: 1;
  padding-inline-end: ${({ theme }) => theme.spacing.doubleSpacing};
  ${({ theme }) => theme.mediaQueries.md} {
    align-self: center;
  }
`;

const ButtonText = styled.span`
  ${visuallyHiddenStyles}
`;

const SelectWrapper = styled.div`
  flex-grow: 1;
`;

const CloseButton = styled(Button)`
  ${({ theme }) => theme.mediaQueries.smMax} {
    position: absolute;
    top: ${({ theme }) => theme.spacing.halfSpacing};
    right: ${({ theme }) => theme.spacing.halfSpacing};

    [dir='rtl'] & {
      right: auto;
      left: ${({ theme }) => theme.spacing.halfSpacing};
    }
  }

  ${({ theme }) => theme.mediaQueries.mdMax} {
    align-self: baseline;
  }
`;

export {
  Wrapper,
  InnerWrapper,
  RegionSwitcherForm,
  LabelText,
  ButtonText,
  SelectWrapper,
  CloseButton,
};
