const LAYOUT_BLOCK_TYPES = {
  CTA_LIST: 'callToActionListBlock',
  SINGLE_COLUMN: 'singleColumnBlock',
  TWO_COLUMN: 'twoColumnBlock',
  THREE_COLUMN: 'threeColumnBlock',
  FOUR_COLUMN: 'fourColumnBlock',
  BLOG_POST_BLOCK: 'blogPostBlock',
};

// Base UI elements
const UI_ATOM_BLOCK_TYPES = {
  BUTTON_LINK: 'buttonLinkBlock',
  CLASS_INFO: 'classInfoBlock',
  HEADING: 'headingBlock',
  IFRAME: 'iFrameBlock',
  IMAGE: 'Image', // Note capitalisation. Only used as part of other images(?)
  JOIN_BUTTON: 'joinButtonBlock',
  LINK_LIST: 'linkListBlock',
  LIST: 'listBlock',
  METADATA: 'metadataBlock',
  PARAGRAPH: 'paragraphBlock',
  QUESTION_LINK: 'questionLinkBlock',
  RESPONSIVE_IMAGE: 'responsiveImageBlock',
  RICH_TEXT: 'richTextBlock',
  SMALL_IMAGE: 'smallImageBlock', // Small is a misnomer. Images have an `imageSize` property in their settings
  U16_GYM_PICKER: 'u16GymPickerBlock',
  VIDEO: 'embeddedVideoBlock',
};

// Complex/grouped UI Blocks
const UI_MOLECULE_BLOCK_TYPES = {
  ACCORDION: 'accordionListBlock',
  BREADCRUMB: 'breadcrumbListBlock',
  BREADCRUMB_LIST: 'breadcrumbListBlock',
  CARD: 'cardBlock',
  CARD_LIST: 'cardListBlock',
  CAROUSEL: 'carouselBlock',
  CATEGORY_LINK: 'categoryLinkBlock',
  CATEGORY_LINK_LIST: 'categoryLinkListBlock',
  CLASS_BENEFIT_LIST: 'classBenefitListBlock',
  CONTACT_LINK: 'contactLinkBlock',
  CONTACT_LINK_LIST: 'contactLinkListBlock',
  CHAT_LAUNCH: 'pureChatLaunchBlock',
  CTA: 'callToActionBlock',
  HELP_CENTRE_HEADING: 'helpCentreHeadingBlock',
  HERO: 'heroBlock',
  QUESTION_LINK_LIST: 'questionLinkListBlock',
  QUOTE: 'quoteBlock',
  SPLIT_BACKGROUND: 'splitBackgroundBlock',
  SPLIT_BACKGROUND_IMAGE_CONTENT: 'splitBackgroundImageContentBlock',
  SPLIT_BACKGROUND_TEXT_CONTENT: 'splitBackgroundTextContentBlock',
  TABS: 'tabsBlock',
  MODAL: 'modalBlock',
  // Blog-specific blocks
  BLOG_CATEGORY_LINK_LIST: 'blogCategoryLinkListBlock',
  BLOG_PRIMARY_CATEGORY_LINK_LIST: 'blogPrimaryCategoryLinkListBlock',
  BLOG_SECONDARY_CATEGORY_LINK_LIST: 'blogSecondaryCategoryLinkListBlock',
  BLOG_POST_LINK: 'blogPostLinkBlock',
  BLOG_POST_LINK_LIST: 'blogPostLinkListBlock',
  BLOG_POST_RELATED_POSTS: 'blogPostRelatedPostsBlock',
  LATEST_BLOG_POST: 'latestBlogPostBlock',
  // Classes specific blocks
  RELATED_CLASSES_LIST: 'relatedClassesListBlock',
  FITNESS_CLASS_CATEGORIES: 'fitnessClassCategoriesBlock',
  CLASS_BENEFIT_GUIDE: 'classBenefitGuideBlock',
  FIND_YOUR_GYM: 'findYourGymBlock',
  TRUSTPILOT: 'trustpilotBlock',
};

// Framework Blocks
const FRAMEWORK_BLOCK_TYPES = {
  CHAT_FRAMEWORK: 'pureChatFrameworkBlock',
};

const UI_BLOCK_TYPES = {
  ...UI_ATOM_BLOCK_TYPES,
  ...UI_MOLECULE_BLOCK_TYPES,
};

const BLOCK_TYPES = {
  ...LAYOUT_BLOCK_TYPES,
  ...UI_BLOCK_TYPES,
  ...FRAMEWORK_BLOCK_TYPES,
};

const BLOCK_LAYOUTS = {
  EQUAL: 'Equal',
  FULL_WIDTH: 'Full Width',
  INDENTED: 'Indented',
  LEFT_OFFSET: 'Left Offset',
  RIGHT_OFFSET: 'Right Offset',
};

// Used for heading, images and video
const BLOCK_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const TEXT_ALIGNMENTS = {
  LEFT: 'Left',
  CENTER: 'Center',
  RIGHT: 'Right',
};

const VERTICAL_ALIGNMENTS = {
  START: 'Start',
  CENTER: 'Center',
  END: 'End',
};

const FLEX_DIRECTIONS = {
  ROW: 'Row',
  COLUMN: 'Column',
};

const CONTENT_TYPES = {
  CATEGORY_THEME_COLOR: 'categoryThemeColor',
  MARKDOWN: 'markdown',
  THEME_COLOR: 'themeColor',
};

/** @see https://designguide.puregym.com/pureui?path=/story/guidance-colors--default */
const THEME_COLOR_PALETTE_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const THEME_COLOR_TYPES = {
  ...THEME_COLOR_PALETTE_TYPES,
  GENERAL: 'general',
  ACCENTS: 'accents',
  ALERTS: 'alerts',
};

const CATEGORY_THEME_COLORS = {
  BLACK: 'Black',
  BLUE: 'Blue',
  GREY: 'Grey',
  RED: 'Red',
  WHITE: 'White',
};

export {
  BLOCK_LAYOUTS,
  BLOCK_SIZES,
  BLOCK_TYPES,
  CATEGORY_THEME_COLORS,
  CONTENT_TYPES,
  FLEX_DIRECTIONS,
  LAYOUT_BLOCK_TYPES,
  TEXT_ALIGNMENTS,
  THEME_COLOR_PALETTE_TYPES,
  THEME_COLOR_TYPES,
  UI_ATOM_BLOCK_TYPES,
  UI_MOLECULE_BLOCK_TYPES,
  UI_BLOCK_TYPES,
  VERTICAL_ALIGNMENTS,
};
