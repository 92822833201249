import { RichText } from '~/ui';

const RichTextBlock = ({
  data: {
    content: { richText },
    settings,
  },
}) => {
  return <RichText data={richText} settings={settings} />;
};

export { RichTextBlock };
