import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTheme } from '@puregym/ui';
import { initCookieConsent } from '@puregym/cookieconsent';

import { usePageState } from '~/context';

// We only allow a subset of tags - scripts and styles are dealt with already
const VALID_META_TAGS = ['base', 'link', 'meta', 'title'];

const getOrgSchemaData = (orgSchema, metadata) => {
  const contactPoints = orgSchema.contactPoints?.length
    ? orgSchema.contactPoints.map((contact) => ({
        '@type': 'ContactPoint',
        telephone: contact.content.contactNumber,
        contactType: contact.content.contactType,
        areaServed: contact.content.areaServed,
        availableLanguage: contact.content.availableLanguage,
      }))
    : null;

  const sameAsLinks = orgSchema.sameAs?.length
    ? orgSchema.sameAs.flatMap((urls) => {
        return urls.content.urls || urls.content.urls > 0
          ? urls.content.urls.map(({ name, type, ...rest }) => rest) // eslint-disable-line no-unused-vars
          : null;
      })
    : null;

  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: orgSchema.name,
    alternateName: orgSchema.alternateName,
    url: metadata.meta.find((x) => x.property === 'og:url').content,
    logo: metadata.meta.find((x) => x.property === 'og:image').content,
    contactPoint: contactPoints,
    sameAs: sameAsLinks,
  });
};

const HelmetScripts = () => {
  const { global } = usePageState();
  const { colors } = useTheme();

  const googleAnalyticsId = global.googleAnalyticsId;
  const abTastyId = global.abTastyId;
  const currentCulture = global.languages?.currentCulture;
  const languageCode = currentCulture?.languageCode;
  const metadata = global.metadata || {};
  const trackingPermissionRequired = global.trackingPermissionRequired;
  const organisationalSchema = global.organisationalSchema;

  const baseMetaTags = [
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
    { name: 'theme-color', content: colors.primary.main },
  ];

  /**
   * Generate Helmet props from metadata. Only include data from
   * allowed keys, and augment global meta with our base meta tags.
   */
  const metaTags = Object.fromEntries(
    Object.entries(metadata).map(([key, val]) => {
      const newVal = key === 'meta' ? [...val, ...baseMetaTags] : val;
      return VALID_META_TAGS.includes(key) ? [key, newVal] : undefined;
    })
  );

  const htmlAttrs = {
    lang: currentCulture?.cultureCode,
    dir: currentCulture?.rtl ? 'rtl' : 'ltr',
  };

  useEffect(() => {
    trackingPermissionRequired && initCookieConsent(languageCode);
  }, [languageCode, trackingPermissionRequired]);

  return (
    <Helmet htmlAttributes={htmlAttrs} {...metaTags}>
      {organisationalSchema && (
        <script type="application/ld+json">
          {getOrgSchemaData(organisationalSchema, metadata)}
        </script>
      )}
      {googleAnalyticsId && (
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window, document, 'script', 'dataLayer', '${googleAnalyticsId}');
          `}
        </script>
      )}
      {googleAnalyticsId && (
        <noscript>
          {`
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=${googleAnalyticsId}"
              height="0"
              width="0"
              style="display: none; visibility: hidden"
            ></iframe>
          `}
        </noscript>
      )}
      {abTastyId && (
        <script
          type="text/javascript"
          src={`https://try.abtasty.com/${abTastyId}.js`}
        ></script>
      )}
    </Helmet>
  );
};

export { HelmetScripts };
