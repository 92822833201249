import { useEffect, useRef, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { PageHeader } from '@puregym/ui';
import { SkipLink } from '@puregym/ui';

import { usePageState } from '~/context';
import { RegionSwitcher, StraplineBanner } from '~/ui';
import { UserMenu } from './userMenu';
import { Nav } from './nav';
import { Logo } from './logo';
import { ClientOnly } from './clientOnly';

const StyledHeader = styled(PageHeader)`
  position: static;
  @media print {
    display: none;
  }
`;

const StickyWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
`;

/**
 * Set `scroll-padding-top` based on header height,
 * to ensure anchor elements aren't hidden on load
 */
const GlobalScrollPadding = createGlobalStyle`
  html { scroll-padding-top: ${({ headerHeight }) =>
    headerHeight && `${headerHeight}px`}; }
`;

const SiteHeader = () => {
  const { global, labels } = usePageState();
  const [headerHeight, setHeaderHeight] = useState(null);
  const headerRef = useRef(null);

  const currentCulture = global.languages?.currentCulture;
  const stickyBanner = global.banner?.isSticky;
  const regionsApiEndpoint = process.env.REGIONS_API_ENDPOINT;

  useEffect(() => {
    const headerObserver = new ResizeObserver(() =>
      setHeaderHeight(headerRef.current?.clientHeight)
    );

    headerObserver.observe(headerRef.current);

    return () => headerObserver.disconnect();
  }, [headerRef]);

  return (
    <>
      <GlobalScrollPadding headerHeight={headerHeight} />
      <StickyWrapper ref={headerRef}>
        <SkipLink href="#main-content">
          {labels.core.skipToMainContent}
        </SkipLink>
        {regionsApiEndpoint && (
          <ClientOnly>
            <RegionSwitcher apiEndpoint={regionsApiEndpoint} />
          </ClientOnly>
        )}
        <StyledHeader data-testid="site-header">
          <Nav />
          <div>
            <Logo brand={global.brand} href={currentCulture?.contentRootUrl} />
          </div>
          <UserMenu showLanguageLink={true} />
        </StyledHeader>
        {stickyBanner && <StraplineBanner />}
      </StickyWrapper>
      {!stickyBanner && <StraplineBanner />}
    </>
  );
};

export { SiteHeader };
