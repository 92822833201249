import styled, { css } from 'styled-components';
import { Heading, maxWidthLayoutStyles } from '@puregym/ui';

import {
  BLOCK_LAYOUTS,
  BLOCK_TYPES,
  TEXT_ALIGNMENTS,
  VERTICAL_ALIGNMENTS,
} from './constants';

const debugStyles = css`
  outline: 1px dashed rgba(0, 0, 0, 0.2);

  &:hover::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
    pointer-events: none;
  }
`;

const equalHeightStyles = css`
  height: 100%;

  > img,
  > iframe,
  > video,
  > embed {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const BlockWrapper = styled.div`
  position: relative;
  padding: ${({ theme }) =>
    `${theme.spacing.doubleSpacing} ${theme.spacing.threeQuarterSpacing}`};
  background-color: ${({ bgColor }) => bgColor};

  ${({ textColor }) =>
    textColor &&
    css`
      color: ${textColor};
      ${Heading}, a {
        color: ${textColor};
      }

      a:hover,
      a:focus,
      a:active {
        color: ${textColor};
      }
    `}

  ${({ theme }) => theme.mediaQueries.md} {
    padding: ${({ theme }) =>
      `${theme.BASE_SPACING * 4}px ${theme.spacing.doubleSpacing}`};
  }
`;

// Sets max-width from the theme. Parent of `Grid`, allows for % sizing
const GridWrapper = styled.div`
  ${maxWidthLayoutStyles}
  margin-top: 0;
  margin-bottom: 0;
`;

const Grid = styled.div`
  position: relative;
  display: ${({ blockType }) =>
    blockType === BLOCK_TYPES.SINGLE_COLUMN ? 'flex' : 'grid'};
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.doubleSpacing};
  width: 100%;

  align-self: ${({ verticalAlignment }) => {
    switch (verticalAlignment) {
      case VERTICAL_ALIGNMENTS.START:
        return 'start';
      case VERTICAL_ALIGNMENTS.CENTER:
        return 'center';
      case VERTICAL_ALIGNMENTS.END:
        return 'end';
      // no default
    }
  }};

  ${({ textAlignment }) => {
    switch (textAlignment) {
      case TEXT_ALIGNMENTS.LEFT:
        return css`
          align-items: start;
        `;
      case TEXT_ALIGNMENTS.CENTER:
        return css`
          align-items: center;
          text-align: center;
        `;
      case TEXT_ALIGNMENTS.RIGHT:
        return css`
          align-items: end;
        `;
      default:
        return css`
          align-items: start;
        `;
    }
  }};

  ${({ blockType, equalHeight }) =>
    blockType === BLOCK_TYPES.SINGLE_COLUMN &&
    equalHeight &&
    equalHeightStyles};

  ${({ debug }) => debug && debugStyles}

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: ${({ columns }) =>
      `repeat(${columns}, minmax(0, 1fr))`};
    gap: ${({ theme }) => theme.spacing.doubleSpacing};
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    ${({ layout }) => {
      switch (layout) {
        case BLOCK_LAYOUTS.INDENTED:
          return css`
            width: 66.66%;
            margin: auto;
          `;
        case BLOCK_LAYOUTS.LEFT_OFFSET:
          return css`
            grid-template-columns: ${({ offset }) => (offset ? offset : '42')}fr ${({
                offset,
              }) => (offset ? 100 - offset : '58')}fr;
          `;
        case BLOCK_LAYOUTS.RIGHT_OFFSET:
          return css`
            grid-template-columns: ${({ offset }) =>
                offset ? 100 - offset : '58'}fr ${({ offset }) =>
                offset ? offset : '42'}fr;
          `;
        // no default
      }
    }}
  }
`;

export { BlockWrapper, GridWrapper, Grid };
