import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@puregym/ui';
import { Blocks } from '~/blocks';

const TabsBlock = ({ data: { content } }) => (
  <Tabs
    css={`
      width: 100%;
    `}
  >
    <TabList>
      {content.blocks?.map((data) => (
        <Tab key={`tab-${data.key}`}>{data.content?.heading}</Tab>
      ))}
    </TabList>
    <TabPanels>
      {content.blocks?.map((data) => (
        <TabPanel key={`tabPanel-${data.key}`}>
          <Blocks data={data.content?.blocks} />
        </TabPanel>
      ))}
    </TabPanels>
  </Tabs>
);

export { TabsBlock };
