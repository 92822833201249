import { Grid } from '@puregym/ui';

import { ContactLinkBlock } from './contactLinkBlock';

const ContactLinkListBlock = ({ data: { content } }) => {
  return (
    <Grid minItemWidth="260px">
      {content.blocks.map((link) => (
        <ContactLinkBlock data={link} key={link.key} />
      ))}
    </Grid>
  );
};

export { ContactLinkListBlock, ContactLinkBlock };
