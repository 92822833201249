import styled, { css } from 'styled-components';
import {
  BUTTON_VARIANTS,
  Button,
  Grid,
  Grow,
  HEADING_VARIANTS,
  Heading,
  Text,
} from '@puregym/ui';

import { usePageState } from '~/context';
import { IMAGE_EAGER_LOAD_LIMIT, IMAGE_LOADING_BEHAVIOR } from '~/constants';
import { CATEGORY_THEME_COLORS } from './constants';

const CategoryGrid = styled(Grid)`
  gap: 0;
`;

const ClassImageWrapper = styled.div`
  width: 240px;
  aspect-ratio: 3 / 2;
  overflow: hidden;
`;

const ClassImage = styled.img`
  display: block;
  max-width: 100%;
  transition: ${({ theme }) => theme.transitionsMs.default};
`;

const FitnessClassCategory = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.baseSpacing};
  padding: ${({ theme }) =>
    `${theme.spacing.doubleSpacing} ${theme.spacing.threeQuarterSpacing}`};
  text-align: center;
  text-decoration: none;

  ${Heading} {
    color: ${({ theme }) => theme.colors.text};
  }

  &:hover,
  &:focus,
  &:active {
    ${ClassImage} {
      transform: scale(1.05);
    }
  }

  ${({ theme }) => theme.mediaQueries.md} {
    gap: ${({ theme }) => theme.spacing.threeQuarterSpacing};
    padding: ${({ theme }) =>
      `${theme.BASE_SPACING * 4}px ${theme.spacing.doubleSpacing}`};
  }

  ${({ categoryTheme, theme }) => {
    switch (categoryTheme) {
      case CATEGORY_THEME_COLORS.RED:
        return css`
          background-color: #f1605a;
          ${Heading} {
            color: ${theme.colors.contrastText};
          }
        `;
      case CATEGORY_THEME_COLORS.BLACK:
        return css`
          background-color: #0d0d0d;
          color: ${theme.colors.contrastText};
          ${Heading} {
            color: ${theme.colors.contrastText};
          }
        `;
      case CATEGORY_THEME_COLORS.BLUE:
        return css`
          background-color: #1a4b72;
          color: ${theme.colors.contrastText};
          ${Heading} {
            color: ${theme.colors.contrastText};
          }
        `;
      case CATEGORY_THEME_COLORS.GREY:
        return css`
          background-color: #9bcdc9;
          color: #e4feff;
          ${Heading} {
            color: #e4feff;
          }
        `;
      case CATEGORY_THEME_COLORS.WHITE:
        return css`
          color: ${theme.colors.text};
          ${Heading} {
            color: ${theme.colors.text};
          }
        `;
      // no-default
    }
  }};
`;

const NumberOfClasses = styled.div`
  text-transform: uppercase;
  font-weight: bold;
`;

const FitnessClassCategoryBlock = ({ data, classesLabel, index }) => {
  const { description, image, name, numberOfClasses, theme, url } = data;
  const { labels } = usePageState();

  const loadingBehavior =
    index <= IMAGE_EAGER_LOAD_LIMIT
      ? IMAGE_LOADING_BEHAVIOR.EAGER
      : IMAGE_LOADING_BEHAVIOR.LAZY;

  const title = image?.title;
  let imgSrc = image?.src;

  if (imgSrc) {
    imgSrc += imgSrc.includes('?')
      ? '&width=240&height=160&mode=crop'
      : '?width=240&height=160&mode=crop';
  }

  return (
    <FitnessClassCategory categoryTheme={theme}>
      {imgSrc && (
        <ClassImageWrapper>
          <ClassImage
            loading={loadingBehavior}
            src={imgSrc}
            title={title}
            width={240}
            height={160}
          />
        </ClassImageWrapper>
      )}
      <Heading as="h4" variant={HEADING_VARIANTS.ALPHA} style={{ margin: 0 }}>
        {name}
      </Heading>
      <NumberOfClasses>
        {numberOfClasses} {classesLabel}
      </NumberOfClasses>
      <Grow>
        <Text grow>{description}</Text>
      </Grow>
      <Button
        as="a"
        href={url}
        $stretch={true}
        variant={BUTTON_VARIANTS.OUTLINE}
      >
        {labels.core.seeAll}
      </Button>
    </FitnessClassCategory>
  );
};

const FitnessClassCategoriesBlock = ({ data: { content, key } }) => {
  if (!content.categories?.length) return null;

  return (
    <CategoryGrid minItemWidth="260px">
      {content.categories.map((category, index) => (
        <FitnessClassCategoryBlock
          key={`${key}-${category.name}`}
          data={category}
          classesLabel={content?.classesLabel}
          index={index}
        />
      ))}
    </CategoryGrid>
  );
};

export { FitnessClassCategoriesBlock };
