import styled, { css } from 'styled-components';
import { List, ListItem, LIST_VARIANTS, IconCheck } from '@puregym/ui';

import { RichText, Wrapper } from '~/ui';

const LIST_STYLES = {
  BULLET: 'Bullet',
  CHECK: 'Checked',
  NUMBERED: 'Numbered',
};

const numberedListStyles = css`
  position: relative;
  list-style: none;
  counter-increment: pure-counter;
  margin-block-end: ${({ theme }) => theme.spacing.halfSpacing};
  padding-inline-start: ${({ theme }) => `${theme.BASE_SPACING * 3.5}px`};

  &::before {
    content: counter(pure-counter);
    position: absolute;
    top: ${({ theme }) => `-${theme.spacing.quarterSpacing}`};
    left: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${({ theme }) => theme.spacing.doubleSpacing};
    height: ${({ theme }) => theme.spacing.doubleSpacing};
    padding: 1.5rem;
    background-color: ${({ theme }) => theme.colors.primary.main};
    border-radius: 50%;
    line-height: 1;
    font-family: ${({ theme }) => theme.fonts.heading};
    color: ${({ theme }) => theme.colors.primary.contrastText};

    [dir='rtl'] & {
      left: auto;
      right: 0;
      font-family: ${({ theme }) => theme.rtlFonts.heading};
    }
  }
`;

const StyledList = styled(List)`
  ${({ listStyle }) => {
    switch (listStyle) {
      case LIST_STYLES.CHECK:
        return css`
          list-style: none;
        `;
      case LIST_STYLES.NUMBERED:
        return css`
          list-style: none;
          padding: 0;
        `;
      // no default
    }
  }}
`;

const StyledListItem = styled(ListItem)`
  ${({ listStyle }) => {
    switch (listStyle) {
      case LIST_STYLES.NUMBERED:
        return numberedListStyles;
      // no default
    }
  }}
`;

const ListItemWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const SubTextWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  align-items: center;
`;

const ListIcon = styled(IconCheck)`
  position: absolute;
  top: 0.33em;
  left: -1.5em;

  [dir='rtl'] & {
    left: auto;
    right: -1.5em;
  }
`;

const ListItemBlock = ({ data, listStyle }) => {
  const { heading, link, subText } = data;
  const url = link?.url;
  const name = link?.name;

  return (
    <StyledListItem listStyle={listStyle}>
      <ListItemWrapper>
        {listStyle === LIST_STYLES.CHECK && <ListIcon aria-hidden={true} />}
        {heading && (
          <Wrapper
            condition={url}
            wrapper={(children) => (
              <a href={url} title={name}>
                {children}
              </a>
            )}
          >
            {heading}
          </Wrapper>
        )}
        {subText && (
          <SubTextWrapper>
            <Wrapper
              condition={url && !heading}
              wrapper={(children) => (
                <a href={url} title={name}>
                  {children}
                </a>
              )}
            >
              <RichText data={subText} />
            </Wrapper>
          </SubTextWrapper>
        )}
      </ListItemWrapper>
    </StyledListItem>
  );
};

const ListBlock = ({ data: { content, settings } }) => {
  const { items } = content;
  const style = settings?.style;

  return (
    <StyledList
      as={style === LIST_STYLES.NUMBERED ? 'ol' : 'ul'}
      listStyle={style}
      variant={LIST_VARIANTS.STANDARD}
    >
      {items.map(({ content, key }) => (
        <ListItemBlock data={content} listStyle={style} key={key} />
      ))}
    </StyledList>
  );
};

export { ListBlock };
