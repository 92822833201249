import { useEffect, useState, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, BUTTON_VARIANTS, Select, IconClose } from '@puregym/ui';
import { CONSENT_TYPES, getUserConsent } from '@puregym/cookieconsent';

import { usePageState } from '~/context';
import { isValidUrl } from '~/utils';

import {
  Wrapper,
  InnerWrapper,
  RegionSwitcherForm,
  LabelText,
  ButtonText,
  SelectWrapper,
  CloseButton,
} from './styles';

const RegionSwitcher = ({ apiEndpoint }) => {
  const [visible, setVisible] = useState(false);
  const { global, labels } = usePageState();
  const inputRef = useRef(null);

  const brandID = global.brand?.id?.toLowerCase();
  const rsOptOutItemName = `@${brandID}/ui/rs_opt_out`;
  const rsOptOut = JSON.parse(window.localStorage.getItem(rsOptOutItemName));

  // Get region site data from our API - if opted out already, do nothing
  const { isLoading, data } = useQuery({
    queryKey: ['regionData'],
    queryFn: () => fetch(`${apiEndpoint}/site`).then((res) => res.json()),
    enabled: !rsOptOut,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const sites = data?.sites;
  const suggestedSiteUrl = data?.suggestedSiteUrl;
  const { origin } = window.location;

  useEffect(() => {
    /**
     * Only show if:
     * 1. API call succeeded and returned site data
     * 2. No opt out/dimissal
     * 3. We are not already at the suggested URL
     */
    if (
      sites &&
      !rsOptOut &&
      !suggestedSiteUrl?.toLowerCase().includes(origin)
    ) {
      setVisible(true);
    }
  }, [sites, origin, suggestedSiteUrl, rsOptOut]);

  const onCloseClick = () => {
    setVisible(false);

    if (
      !global.trackingPermissionRequired ||
      getUserConsent(CONSENT_TYPES.FUNCTIONAL)
    ) {
      try {
        window.localStorage.setItem(rsOptOutItemName, JSON.stringify('true'));
      } catch (e) {
        console.error('Setting localStorage item failed:', e);
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const destinationUrl = inputRef.current.value;

    if (isValidUrl(destinationUrl)) {
      window.location.href = destinationUrl;
    }
  };

  return (
    visible &&
    !isLoading && (
      <Wrapper>
        <InnerWrapper>
          <RegionSwitcherForm onSubmit={onSubmit}>
            <LabelText>{labels.core.regionSwitcherLabel}</LabelText>
            <SelectWrapper>
              <Select ref={inputRef}>
                {sites.map(({ countryName, id, url }) => (
                  <option key={`site-option-${id}`} value={url}>
                    {countryName}
                  </option>
                ))}
              </Select>
            </SelectWrapper>
            <Button variant={BUTTON_VARIANTS.SUBDUED}>
              {labels.core.continue}
            </Button>
          </RegionSwitcherForm>
          <CloseButton variant={BUTTON_VARIANTS.PLAIN} onClick={onCloseClick}>
            <IconClose title={labels.core.closeMenuButton} aria-hidden={true} />
            <ButtonText>{labels.core.closeMenuButton}</ButtonText>
          </CloseButton>
        </InnerWrapper>
      </Wrapper>
    )
  );
};

export { RegionSwitcher };
