import styled from 'styled-components';
import { Page as UiPage, PageMain, Spinner } from '@puregym/ui';

import { usePageState } from '~/context';
import {
  ClassPage,
  Home,
  Error,
  StandardPage,
  BlogPostPage,
  ClassCategoryOverviewPage,
} from '~/pages';
import { HelmetScripts, SiteHeader, SiteFooter } from '~/ui';
import { PAGE_TYPES } from './constants';

const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const renderPageBody = (pageType) => {
  switch (pageType) {
    case PAGE_TYPES.ERROR_404:
    case PAGE_TYPES.ERROR_500:
      return <Error />;
    case PAGE_TYPES.HOME:
      return <Home />;
    case PAGE_TYPES.GYM:
    case PAGE_TYPES.GYMS:
    case PAGE_TYPES.HELP_CENTRE:
    case PAGE_TYPES.HELP_CENTRE_CATEGORY:
    case PAGE_TYPES.HELP_CENTRE_QUESTION:
    case PAGE_TYPES.STANDARD:
    case PAGE_TYPES.HTML_SITE_MAP:
    case PAGE_TYPES.BLOG:
    case PAGE_TYPES.BLOG_CATEGORY:
      return <StandardPage />;
    case PAGE_TYPES.BLOG_POST:
      return <BlogPostPage />;
    case PAGE_TYPES.CLASS_PAGE:
    case PAGE_TYPES.CLASS_CATEGORY_DETAILS_PAGE:
      return <ClassPage />;
    case PAGE_TYPES.CLASS_CATEGORY_OVERVIEW_PAGE:
      return <ClassCategoryOverviewPage />;
    default:
      return process.env.NODE_ENV === 'development' ? (
        <p>Unknown page type</p>
      ) : null;
  }
};

const Page = () => {
  const { global, isFetchingData, page } = usePageState();
  const { contentType } = page;
  const { isEmbeddedBrowser } = global;

  return (
    <UiPage>
      <HelmetScripts />
      {!isEmbeddedBrowser && <SiteHeader />}
      <PageMain fullWidth={true}>
        <MainContent id="main-content">
          {isFetchingData || !contentType ? (
            <Spinner cover />
          ) : (
            renderPageBody(contentType)
          )}
        </MainContent>
      </PageMain>
      {!isEmbeddedBrowser && <SiteFooter />}
    </UiPage>
  );
};

export { Page };
