export const UserKeys = {
  Name: 'Name',
  Email: 'EmailAddress',
  MessageInput: 'MessageInput',
  EmailRegex: '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,64}$',
};

export const validateLiveChatParticipantDetails = (form) => {
  const name = form.chatUserName?.value;
  const email = form.chatUserEmail?.value;

  return {
    name,
    email,
    validationErrors: [
      ...validateContactName(name),
      ...validateEmailAddress(email),
    ],
  };
};

const validateContactName = (value) => {
  if (value == null || value.length === 0) {
    return [
      {
        key: UserKeys.Name,
        message: 'name required',
      },
    ];
  }
  return [];
};

const validateEmailAddress = (value) => {
  const returnValue = [
    {
      key: UserKeys.EmailAddress,
      message: 'email format',
    },
  ];
  try {
    if (value.match(UserKeys.EmailRegex).length === 0) {
      return returnValue;
    }
    return [];
  } catch (err) {
    return returnValue;
  }
};
