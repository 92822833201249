import './shim';

import * as React from 'react'; // Needed, as we re-export for Reactjs.net
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import { ServerStyleSheet } from 'styled-components';
import { Helmet } from 'react-helmet';

import { App } from './app';

global.React = React;
global.ReactDOM = ReactDOM;
global.ReactDOMServer = ReactDOMServer;

global.Styled = { ServerStyleSheet };
global.Helmet = Helmet;

/** Add App to global scope */
global.Components = { App };
