import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { Button } from '@puregym/ui';

import { usePageState } from '~/context';
import ChatUser from './chatUser';

const resolveUserContext = (siteUser) => {
  if (siteUser?.loggedIn) {
    return {
      name: siteUser.details?.name,
      email: siteUser.details?.email,
    };
  }

  const searchParams = new window.URLSearchParams(document.location.search);
  const name = searchParams.get('name') ?? searchParams.get('user');
  const email = searchParams.get('email');

  return {
    name,
    email,
  };
};

const PureChatLaunchBlock = ({ data: { content } }) => {
  const { autoOpenMessenger, launchButtonText } = content;
  const {
    global: { user },
  } = usePageState();

  const [userIdentity, setUserIdentity] = useState(null);
  const [isChatReady, setIsChatReady] = useState(false);

  useEffect(() => {
    setUserIdentity(resolveUserContext(user));
  }, [user]);

  useEffect(() => {
    const ensureClosed = () => {
      if (!window.pg_isGenesysMessengerOpen()) return;
      window.Genesys('command', 'Messenger.close');
    };

    window.addEventListener('beforeunload', ensureClosed);
    window.Genesys('subscribe', 'Messenger.ready', () => {
      setIsChatReady(true);
    });

    return () => {
      window.removeEventListener('beforeunload', ensureClosed);
    };
  }, []);

  const userIdentityIsValid = () =>
    userIdentity?.name != null && userIdentity?.email != null;

  const handleUserIdentityChange = (name, email) => {
    setUserIdentity({
      name,
      email,
    });
  };

  const showMessenger = () => {
    if (window.pg_isGenesysMessengerOpen()) return;

    window.Genesys('command', 'Database.set', {
      messaging: {
        customAttributes: {
          userName: userIdentity.name,
          userEmail: userIdentity.email,
        },
      },
    });

    window.Genesys('command', 'Messenger.open');
  };

  if (userIdentity == null) return null;
  if (!userIdentityIsValid()) {
    return (
      <ChatUser
        resources={{
          nameLabel: 'Name',
          emailLabel: 'Email',
          notLoggedInWelcome:
            'As you are not logged in please tell us a little more about yourself before you connect to one of our team.',
          beginChatButtonText: 'Start Chat',
        }}
        onComplete={handleUserIdentityChange}
      />
    );
  }

  if (!isChatReady) return null;
  if (autoOpenMessenger) {
    showMessenger();
  } else {
    return (
      <Button as="a" disabled={!isChatReady} onClick={() => showMessenger()}>
        {launchButtonText}
      </Button>
    );
  }

  return null;
};

export { PureChatLaunchBlock };

PureChatLaunchBlock.defaultProps = {
  data: {
    content: {
      autoOpenMessenger: false,
      launchButtonText: 'Start Chat',
    },
  },
};

PureChatLaunchBlock.propTypes = {
  userContext: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  data: PropTypes.shape({
    content: {
      autoOpenMessenger: PropTypes.bool,
      launchButtonText: PropTypes.string,
    },
  }),
};
