import produce from 'immer';

import { ACTIONS, EMBEDDED_USER_AGENTS } from './constants';

const initialState = {
  isFetchingData: false,
  page: {},
  global: {},
  labels: {
    core: {},
    global: {},
  },
};

/**
 * State is wrapped in `produce` from immer.
 *
 * @see https://immerjs.github.io/immer/docs/update-patterns
 **/
const pageReducer = produce((state, action) => {
  const { type, payload } = action;

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('%c Action: ', 'background: #222; color: #bada55', action);
  }

  switch (type) {
    case ACTIONS.GET_GLOBAL_DATA:
    case ACTIONS.GET_PAGE_DATA: {
      state.isFetchingData = true;

      return;
    }

    case ACTIONS.GET_GLOBAL_DATA_SUCCESS: {
      const userAgent = payload.device?.userAgent;

      // Check if we are embedded in our app/kiosk, and set flag
      const userAgents = Object.keys(EMBEDDED_USER_AGENTS).reduce(
        (a, c) => ((a[c] = EMBEDDED_USER_AGENTS[c].toLowerCase()), a),
        {}
      );

      const isEmbeddedBrowser =
        userAgent &&
        Object.values(userAgents).some((ua) =>
          ua.includes(userAgent.toLowerCase())
        );

      state.isFetchingData = false;
      state.global = { ...payload, isEmbeddedBrowser };

      return;
    }

    case ACTIONS.GET_PAGE_DATA_SUCCESS: {
      state.isFetchingData = false;
      state.page = payload;

      return;
    }

    case ACTIONS.GET_GLOBAL_DATA_ERROR:
    case ACTIONS.GET_PAGE_DATA_ERROR: {
      state.isFetchingData = false;

      return;
    }

    case ACTIONS.GET_LABELS_SUCCESS: {
      state.labels = payload;

      return;
    }

    default: {
      // do nothing
    }
  }
});

export { initialState, pageReducer };
