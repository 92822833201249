import styled from 'styled-components';
import { Grid } from '@puregym/ui';

import { BlogPrimaryCategoryLinkListBlock } from './blogPrimaryCategoryLinkListBlock';
import { BlogSecondaryCategoryLinkListBlock } from './blogSecondaryCategoryLinkListBlock';

const BlogCategoryGrid = styled(Grid)`
  grid-template-columns: 1fr;

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: ${({ theme }) =>
      `1fr calc(25% - ${theme.BASE_SPACING * 1.5}px)`};
  }

  ${({ theme }) => theme.mediaQueries.lgMax} {
    gap: ${({ theme }) => theme.spacing.baseSpacing};
  }
`;

const BlogCategoryLinkListBlock = ({ data: { content } }) => (
  <BlogCategoryGrid>
    <BlogPrimaryCategoryLinkListBlock data={content.primaryCategories} />
    <BlogSecondaryCategoryLinkListBlock
      categories={content.secondaryCategories}
      heading={content.moreCategoriesHeading}
    />
  </BlogCategoryGrid>
);

export { BlogCategoryLinkListBlock };
