import { Banner } from '@puregym/ui';

import { usePageState } from '~/context';

const StraplineBanner = () => {
  const { global } = usePageState();
  const banner = global.banner;

  if (!banner) return null;

  return (
    <Banner
      variant={banner.bannerType?.toLowerCase()}
      primaryText={banner.primaryText}
      secondaryText={banner.secondaryText}
      offerCode={banner.boxCode}
      to={banner.callToActionLink?.url}
    />
  );
};

export { StraplineBanner };
