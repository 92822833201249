import { JOIN_URLS, LINK_TARGETS } from '~/constants';

const getLinkTarget = (url) => {
  if (typeof url !== 'string' || !url.startsWith('/')) return null;

  if (JOIN_URLS.some((joinUrl) => url.includes(joinUrl))) {
    return LINK_TARGETS.JOIN;
  }
};

const isValidUrl = (urlString) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export { getLinkTarget, isValidUrl };
