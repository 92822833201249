import styled, { css } from 'styled-components';
import { Button, Card, CARD_VARIANTS, Heading } from '@puregym/ui';
import { Blocks } from '~/blocks';
import { TEXT_ALIGNMENTS } from './constants';

const StyledCard = styled(Card)`
  width: 100%;
  gap: ${({ theme }) => theme.spacing.doubleSpacing};
  color: ${({ theme }) => theme.colors.text};

  > *:last-child {
    margin-block-end: 0;
    :not(:only-child) {
      margin-block-start: auto;
    }
  }

  ${({ variant }) => {
    switch (variant) {
      case CARD_VARIANTS.STANDARD:
      case CARD_VARIANTS.PLAIN:
        return css`
          ${Heading}, a:not(${Button}) {
            color: ${({ theme }) => theme.colors.text};
          }
        `;
      case CARD_VARIANTS.PREMIUM:
        return css`
          color: ${({ theme }) => theme.colors.contrastText};
          ${Heading}, a:not(${Button}) {
            color: ${({ theme }) => theme.colors.contrastText};
          }
        `;
      // no-default
    }
  }};

  ${({ alignment }) => {
    switch (alignment) {
      case TEXT_ALIGNMENTS.CENTER:
        return css`
          text-align: center;
          align-items: center;
        `;
      case TEXT_ALIGNMENTS.RIGHT:
        return css`
          text-align: end;
          align-items: end;
        `;
      default:
        return css`
          text-align: start;
          align-items: start;
        `;
    }
  }};
`;

const CardBlock = ({ data: { content, settings } }) => {
  const alignment = settings?.alignment;
  const variant = settings?.variant || CARD_VARIANTS.STANDARD;

  return (
    <StyledCard alignment={alignment} variant={variant}>
      <Blocks data={content.blocks} />
    </StyledCard>
  );
};

export { CardBlock };
