import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const PureChatFrameworkBlock = ({ data: { content } }) => {
  const { globalFuncUrl, deploymentId, environment } = content;

  return (
    <Helmet>
      <script type="text/javascript">
        {`
            (function (g, e, n, es, ys) {
                g['_genesysJs'] = e;
                g[e] = g[e] || function () {
                    (g[e].q = g[e].q || []).push(arguments);
                };
                g[e].t = 1 * new Date();
                g[e].c = es;
                ys = document.createElement('script');
                ys.async = 1; ys.src = n; ys.charset = 'utf-8';
                document.head.appendChild(ys);
            })(window, 'Genesys', '${globalFuncUrl}', {
                environment: '${environment}',
                deploymentId: '${deploymentId}'
            });
        `}
      </script>
      <script type="text/javascript">
        {`
          window.pg_isGenesysMessengerOpen = function () {
            const lsTracking = JSON.parse(localStorage.getItem('_${deploymentId}:gcmcopn'));
            if (lsTracking?.value == null) return false;
            return (lsTracking.value === 'true');
          };
        `}
      </script>
    </Helmet>
  );
};

export { PureChatFrameworkBlock };

PureChatFrameworkBlock.defaultProps = {
  data: {
    content: {
      globalFuncUrl:
        'https://apps.euw2.pure.cloud/genesys-bootstrap/genesys.min.js',
      deploymentId: 'fc4b1905-4334-45eb-938a-65e7174e6d3b',
      environment: 'euw2',
    },
  },
};

PureChatFrameworkBlock.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.shape({
      globalFuncUrl: PropTypes.string.isRequired,
      deploymentId: PropTypes.string.isRequired,
      environment: PropTypes.string.isRequired,
    }),
  }),
};
