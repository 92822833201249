import styled from 'styled-components';
import { Button, BUTTON_VARIANTS } from '@puregym/ui';
import logo from '@logo/primary-inverted.svg';

const Image = styled.img`
  max-width: 100%;
  height: 50px;
`;

const Logo = ({ brand = {}, href = '/', ...rest }) => (
  <Button as="a" href={href} variant={BUTTON_VARIANTS.PLAIN} {...rest}>
    <Image alt={brand.legalName} src={logo} />
  </Button>
);

export { Logo };
