import { List } from '@puregym/ui';
import { Overlay } from '~/ui';

const generateMetaDataListItems = (data) => {
  const dataKey = data.key;
  try {
    return Object.entries(data).map(([key, value]) => {
      if (!value) {
        return;
      }

      const itemKey = `metaData-${key}-${dataKey}`;

      if (typeof value === 'string') {
        return (
          <li key={itemKey}>
            <span>{key}:</span> <span>{value}</span>
          </li>
        );
      }

      // Only show nested objects if at least one key contains a value
      const showChildren = Object.values(value).some((val) => !!val);

      if (!showChildren) {
        return;
      }

      return (
        <li key={itemKey}>
          <List variant="plain">
            <li>{key}</li>
            {generateMetaDataListItems(value)}
          </List>
        </li>
      );
    });
  } catch (e) {
    console.error('Failed to generate metadata:', e);
  }
};

const BlockMetadata = ({ data }) => (
  <Overlay>
    <List variant="plain">{generateMetaDataListItems(data)}</List>
  </Overlay>
);

export { BlockMetadata, Overlay };
