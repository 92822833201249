import { useState, useEffect } from 'react';

/**
 * ClientOnly is used to defer loading of content. It takes `children`
 * and `fallback` as props.
 *
 * @param {*} children - Returns any `children`
 * @param {ReactElement} fallback - Fallback while `children` are rendering
 *
 */
const ClientOnly = ({ children, fallback = null }) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return fallback;
  }

  return children;
};

export { ClientOnly };
