import styled from 'styled-components';
import { Arrow, List, ListItem, LIST_VARIANTS } from '@puregym/ui';
import { usePageState } from '~/context';
import { Wrapper } from '~/ui';

const BreadcrumbList = styled(List)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => `-${theme.spacing.baseSpacing}`};
  margin-left: ${({ theme }) => `-${theme.spacing.halfSpacing}`};
  font-size: ${({ theme }) => theme.fontSizes.small};

  ${({ theme }) => theme.mediaQueries.md} {
    margin-top: ${({ theme }) => `-${theme.spacing.doubleSpacing}`};
  }

  & > li {
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;

    & > a,
    & > span {
      padding: ${({ theme }) =>
        `${theme.spacing.quarterSpacing} ${theme.spacing.halfSpacing}`};
      text-decoration: none;
    }
  }
`;

const BreadcrumbArrow = styled(Arrow)`
  opacity: 0.66;
  font-size: ${({ theme }) => theme.fontSizes.xxsmall};
`;

const BreadcrumbItemBlock = ({
  data: { content },
  index,
  isCurrentPage,
  isRtl,
}) => {
  const { href, text } = content;

  return (
    <ListItem
      itemProp="itemListElement"
      itemScope
      itemType="https://schema.org/ListItem"
    >
      <Wrapper
        condition={href && !isCurrentPage}
        wrapper={(children) => (
          <>
            <a href={href} itemProp="item">
              {children}
            </a>
            <BreadcrumbArrow
              arrowDirection="forward"
              isRtl={isRtl}
              aria-hidden={true}
            />
          </>
        )}
      >
        <span itemProp="name">{text}</span>
      </Wrapper>
      <meta itemProp="position" content={index} />
    </ListItem>
  );
};

/**
 * @see https://schema.org/BreadcrumbList
 */
const BreadcrumbBlock = ({ data: { content } }) => {
  const { global } = usePageState();
  const isRtl = global.languages.currentCulture?.rtl;
  const breadcrumbItems = content.blocks;

  return (
    <BreadcrumbList
      as="ol"
      variant={LIST_VARIANTS.PLAIN}
      itemscope
      itemtype="http://schema.org/BreadcrumbList"
    >
      {breadcrumbItems.map((item, i) => (
        <BreadcrumbItemBlock
          data={item}
          key={item.key}
          index={i}
          isRtl={isRtl}
          isCurrentPage={breadcrumbItems.length === i + 1}
        />
      ))}
    </BreadcrumbList>
  );
};

export { BreadcrumbBlock, BreadcrumbItemBlock };
