import styled from 'styled-components';

const QuoteWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const QuoteText = styled.figure`
  margin: 0;
  padding: ${({ theme }) => theme.spacing.baseSpacing};
  flex-grow: 1;

  blockquote {
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing.baseSpacing};
    font-size: ${({ theme }) => theme.fontSizes.large};
  }

  figcaption {
    color: ${({ theme }) => theme.colors.primary.dark};

    span {
      display: block;
      font-weight: bold;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 70px;
    height: 40px;
    margin-bottom: ${({ theme }) => theme.spacing.baseSpacing};
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAAAoCAYAAABD0IyuAAAECUlEQVRoge2aTYgcRRTHf9kkSuJHNogQSkQxqMiKEUREkpCDJuglHkTBgwiFlAQhrCKrSETR9SSLCIKkxEKRHDSoaIx4ESWiQWJE/AqiomhSrmLULJpsjLvroXt2Xtd29cx0akbE/p36vXoz782/q1931TQ0lLKomyBl3WbgEWA18BWwzRu9u5+FdVHTGuBJYC0wBWwHHvBG/5Xi+4e6KOBW4FXgMuA04HJgl7JuY4oC6qCsuxTYA6wjO7krgDHgiVQ5KoVR1o0AT5cMLQK2pSqiF5R1y4GXgDNLho2y7qwUeaLCKOuWAjuAUyMhF6UooAaPVeQeAs5NkaRqxtwDrBH2EWBO2L+kKKAXlHVrgS2B++fAPpwiV6kwyrrVwIOB+2GKzfrrFAV0i7JuCdllLWt4DvhW2NPAwRT5YjNmguIl9AawK4jxKQrogTuBS4T9EzAKrJI+b/QcCVggjLLuWuAG4ToObCW7I0n+TFFANyjrhoGHAveYN/p3YKnwTaXKWTZjxgN7whv9DTAb+JekKqILRoFhYb8PPJ8fnxB+KdJJURBGWXcdcJVwHQIezY/Ds7EsVRFV5LNlNHBvFZfMUeEvu4XXIpwxYcMd90a3Eofd//xURXRgjOwBrsVOb/R+YU+K41XKulNSJJ3v8Mq6K4APxdgPwAXe6L9FzI+0m91vwGs1ck4Cu73R73YKzH/kQeBs4R7xRn8hYp4BtBjfSXEWdcMM8BHgvNHHoNgn7giCJ6QoOV/SFmYlcFuPBbS4V1n3uDf67g5xmymK8roUJedAYN9UsyYNbFHWbfBGHx6C+cfsW0TQcbJnhJBPayYt4658cVrF7YG9vSTms0T1AIyQPVnP95hNwOki4OX8VhjyScIiAG6ODeRN9xrhmgTeLAlNebIAboT2pbQpGNwR+dALwD6y7t9pZb4cKGuEr4jjcyo+v4Hipf5iyaWNN/pQvtrudKteDJwRGRsn276A/M7WSixv0bNAaWP0Rk8BH3cooBJlnTSr9oOuDOy3Y4He6M9PsqYF677WWb9Q+A7kAvzbhCvofYNM3hJGTrEki7AErAzsydKoPlHWJ6YHWUAFhZ7hjZ4ZZPKOW5v/VxphMoZDRyNMhEaYCI0wERphIgxUmHz985+gmTERGmEiNMJEaISJ0AgToREmQiNMxoKdvUaYjMWho7W1OYvYGFfWfVfxJXNkr4T0yjQQ7tkeq4gv/DekrCvbnAf4o+R7u2GK9t/OFwv/DLSF+QC4Oj9eBpxXI1Ed9laM7QGuF/aKSFzMX5f3oD1L7mfhn/b95nuylwtjPEX2IuQgOQHcB7kw3uh3gI3AfopvTfWDabJ36NZ5o3+NBXmjjwDrgWfp/9tbR4G3gPXe6L0A/wAkOO+BGbGnlwAAAABJRU5ErkJggg==');
  }

  [dir='rtl'] &::before {
    transform: scaleX(-1);
  }

  ${({ theme }) => theme.mediaQueries.md} {
    padding: ${({ theme }) => theme.spacing.doubleSpacing};

    blockquote {
      font-size: ${({ theme }) => theme.fontSizes.xlarge};
    }

    blockquote,
    figcaption {
      padding-inline-start: 70px; // Match pseudo-element
    }
  }

  ${({ theme }) => theme.mediaQueries.mdMax} {
    padding-block-end: ${({ theme }) => theme.spacing.doubleSpacing};

    figcaption {
      padding-inline-end: ${({ hasImage }) =>
        hasImage && '100px'}; // Image + gap
    }
  }
`;

/** @todo These negative margins are a bit magic: need to expose raw vals in theme to construct them  */
const QuoteImg = styled.img`
  max-width: 200px;
  border-radius: 50%;
  aspect-ratio: 1 / 1;

  ${({ theme }) => theme.mediaQueries.mdMax} {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 80px;

    [dir='rtl'] & {
      right: auto;
      left: 0;
    }
  }
`;

const QuoteBlock = ({ data: { content } }) => {
  const { additionalInfo, author, imageAlternativeText, quote } = content;

  const lazyLoad = content.image?.lazyLoad;
  const imgSrc = content.image?.src;
  let resizedImgSrc;
  let srcSet;

  if (imgSrc) {
    resizedImgSrc = imgSrc.includes('?')
      ? `${imgSrc}&width=200&height=200&mode=crop`
      : `${imgSrc}?width=200&height=200&mode=crop`;

    const highResSrc = imgSrc.includes('?')
      ? `${imgSrc}&width=400&height=400&mode=crop`
      : `${imgSrc}?width=400&height=400&mode=crop`;

    srcSet = `${resizedImgSrc} 1x, ${highResSrc} 2x`;
  }

  return (
    <QuoteWrapper>
      <QuoteText hasImage={imgSrc}>
        <blockquote>
          <p>{quote}</p>
        </blockquote>
        <figcaption>
          <span>{author}</span>
          {additionalInfo}
        </figcaption>
      </QuoteText>
      {imgSrc && (
        <QuoteImg
          loading={lazyLoad ? 'lazy' : null}
          alt={imageAlternativeText}
          src={resizedImgSrc}
          srcSet={srcSet}
        />
      )}
    </QuoteWrapper>
  );
};

export { QuoteBlock };
