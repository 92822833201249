import { useState } from 'react';
import styled from 'styled-components';

import { Button, BUTTON_VARIANTS, Modal } from '@puregym/ui';

import { usePageState } from '~/context';
import { Icon, RichText } from '~/ui';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ContactLink = styled.a`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.baseSpacing};
  padding: ${({ theme }) => theme.spacing.halfSpacing};
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  cursor: pointer;
`;

const ContactIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  aspect-ratio: 1 / 1;
  border: ${({ theme }) => `1px solid ${theme.colors.accents.muted}`};
  border-radius: 1000px;
  box-shadow: ${({ theme }) => theme.boxShadows.default};
  transition: ${({ theme }) => theme.transitionsMs.default};
  font-size: 2.5em;

  @supports not (aspect-ratio: 1 / 1) {
    &::before {
      content: '';
      float: left;
      padding-top: 100%;
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }

  ${ContactLink}:hover &,
  ${ContactLink}:focus &,
  ${ContactLink}:active & {
    background: ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.primary.contrastText};
    border-color: ${({ theme }) => theme.colors.primary.main};
  }
`;

const ContactButton = styled(Button)`
  ${ContactLink}:hover &,
  ${ContactLink}:focus &,
  ${ContactLink}:active & {
    border-color: currentColor;
    color: ${({ theme }) => theme.colors.text};
    background: ${({ theme }) => theme.colors.accents.muted};
  }
`;

const ContactLinkBlock = ({ data: { content } }) => {
  const { labels } = usePageState();
  const [isOpen, setIsOpen] = useState(false);

  const {
    additionalInformation,
    additionalInformationHeading,
    href,
    icon,
    text,
  } = content;

  const hasAdditionalInfo = additionalInformation?.content;

  const onClick = (e) => {
    if (hasAdditionalInfo) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  };

  return (
    <>
      <Wrapper>
        <ContactLink
          as={hasAdditionalInfo ? 'button' : 'a'}
          href={!hasAdditionalInfo ? href : undefined}
          onClick={onClick}
        >
          <ContactIcon>
            <Icon name={icon} aria-hidden={true} />
          </ContactIcon>
          <ContactButton as="div" variant={BUTTON_VARIANTS.OUTLINE}>
            {text}
          </ContactButton>
        </ContactLink>
      </Wrapper>
      {hasAdditionalInfo && (
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          headingText={additionalInformationHeading}
          closeText={labels.core.closeMenuButton}
        >
          <RichText data={additionalInformation.content?.richText} />
          <Button as="a" href={href}>
            {text}
          </Button>
        </Modal>
      )}
    </>
  );
};

export { ContactLinkBlock };
