import { StrictMode, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  pureGymTheme,
  ThemeProvider,
  updateAppElement,
  useDeviceInfo,
} from '@puregym/ui';

import '@puregym/ui/dist/static/css/fonts.css';
import './global.css';

import { PageProvider } from '~/context';
import { initialState } from '~/reducer';
import { Page } from '~/pages';
import { EMBEDDED_USER_AGENTS } from '~/constants';

const queryClient = new QueryClient();

const App = ({ global, labels, page }) => {
  const userAgentServer = global?.device?.userAgent;
  const { userAgent } = useDeviceInfo();
  const ua = userAgentServer || userAgent;

  // Check if we are embedded in our app/kiosk, and set flag
  const embeddedUserAgents = Object.keys(EMBEDDED_USER_AGENTS).reduce(
    (a, c) => ((a[c] = EMBEDDED_USER_AGENTS[c].toLowerCase()), a),
    {}
  );

  const isEmbeddedBrowser =
    ua &&
    Object.values(embeddedUserAgents).some((agent) =>
      agent.includes(ua.toLowerCase())
    );

  const augmentedState = {
    ...initialState,
    ...(global && { global: { ...global, isEmbeddedBrowser } }),
    ...(labels && { labels }),
    ...(page && { page }),
  };

  useEffect(() => updateAppElement('[id^="react_"]'), []);

  return (
    <StrictMode>
      <PageProvider initialState={augmentedState}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={pureGymTheme}>
            <Page />
          </ThemeProvider>
        </QueryClientProvider>
      </PageProvider>
    </StrictMode>
  );
};

export { App };
