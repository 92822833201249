import styled, { css } from 'styled-components';
import { Text } from '@puregym/ui';

import { TEXT_ALIGNMENTS } from './constants';

const StyledText = styled(Text)`
  ${({ alignment }) => {
    switch (alignment) {
      case TEXT_ALIGNMENTS.CENTER:
        return css`
          text-align: center;
          align-self: center;
        `;
      case TEXT_ALIGNMENTS.LEFT:
        return css`
          text-align: start;
          align-self: start;
        `;
      case TEXT_ALIGNMENTS.RIGHT:
        return css`
          text-align: end;
          align-self: end;
        `;
      // no-default
    }
  }};
`;

const ParagraphBlock = ({ data: { content, settings } }) => {
  const { text } = content;
  const alignment = settings?.alignment;

  return <StyledText alignment={alignment}>{text}</StyledText>;
};

export { ParagraphBlock };
