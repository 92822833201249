import { usePageState } from '~/context';
import { Blocks } from '~/blocks';

const ClassCategoryOverviewPage = () => {
  const { page } = usePageState();

  return <Blocks data={page.blocks} />;
};

export { ClassCategoryOverviewPage };
