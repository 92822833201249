import styled, { css } from 'styled-components';
import { Button, Card, Grow, Heading, Text } from '@puregym/ui';

import { BLANK_TARGET } from '~/constants';
import { getLinkTarget } from '~/utils/urls';

import { FLEX_DIRECTIONS, TEXT_ALIGNMENTS } from './constants';

const CtaCard = styled(Card)`
  width: 100%;
  color: ${({ theme }) => theme.colors.text};

  ${Heading} {
    color: ${({ theme }) => theme.colors.primary.main};
  }

  ${({ alignment }) => {
    switch (alignment) {
      case TEXT_ALIGNMENTS.CENTER:
        return css`
          text-align: center;
          align-items: center;
        `;
      case TEXT_ALIGNMENTS.LEFT:
        return css`
          text-align: start;
          align-self: start;
        `;
      case TEXT_ALIGNMENTS.RIGHT:
        return css`
          text-align: end;
          align-items: end;
        `;
      // no-default
    }
  }};

  flex-direction: ${({ flexDirection }) => {
    switch (flexDirection) {
      case FLEX_DIRECTIONS.COLUMN:
        return 'column';
      case FLEX_DIRECTIONS.ROW:
        return 'row';
      // no default
    }
  }};
`;

const CtaImg = styled.img`
  display: block;
  max-width: 100%;
`;

const CtaBlock = ({ data: { content, settings } }) => {
  const { imageAltText, imageUrl, link, text, title } = content;

  if (!link) return null;

  const flexDirection = settings?.layout;
  const alignment = settings?.alignment;

  const { name, target, url } = link;
  const forceNewTab = target === BLANK_TARGET;

  return (
    <CtaCard alignment={alignment} flexDirection={flexDirection}>
      <Heading as="h2">{title}</Heading>
      {imageUrl && imageUrl.length > 0 && (
        <CtaImg src={imageUrl} alt={imageAltText} />
      )}
      <Grow>
        <Text>{text}</Text>
      </Grow>
      {link && (
        <Button
          as="a"
          href={url}
          $stretch={true}
          rel={forceNewTab ? 'noopener' : null}
          target={forceNewTab ? BLANK_TARGET : null}
          data-linktarget={getLinkTarget(url)}
        >
          {name}
        </Button>
      )}
    </CtaCard>
  );
};

export { CtaBlock };
