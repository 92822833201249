import styled, { css } from 'styled-components';
import { Button, Heading, Text, useTheme } from '@puregym/ui';
import {
  BLOCK_TYPES,
  CATEGORY_THEME_COLORS,
  CONTENT_TYPES,
  THEME_COLOR_PALETTE_TYPES,
  THEME_COLOR_TYPES,
} from './constants';
import { Blocks } from './blocks';
import { ButtonBlock } from './buttonBlock';
import { ModalBlock } from './modalBlock';

/**
 * The setup to achieve this layout is a little complex;
 * take note of the different wrapper styles/what each achieves.
 */
const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.md} {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 1fr));
  }
`;

/** Full width wrapper, background image applied here */
const BgBlockWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.md} {
    justify-content: flex-start;

    &:nth-child(odd) {
      justify-content: flex-end;
    }
  }

  background-color: ${({ bgColor }) => bgColor};

  ${({ textColor }) =>
    textColor &&
    css`
      color: ${textColor};
      ${Heading}, a {
        color: ${textColor};
      }

      a:hover,
      a:focus,
      a:active {
        color: ${textColor};
      }
    `}

  ${({ categoryTheme, theme }) => {
    switch (categoryTheme) {
      case CATEGORY_THEME_COLORS.RED:
        return css`
          background-color: #f1605a;
          ${Heading} {
            color: ${theme.colors.text};
          }
        `;
      case CATEGORY_THEME_COLORS.BLACK:
        return css`
          background-color: #0d0d0d;
          color: ${theme.colors.contrastText};
          ${Heading} {
            color: ${theme.colors.contrastText};
          }
        `;
      case CATEGORY_THEME_COLORS.BLUE:
        return css`
          background-color: #1a4b72;
          color: ${theme.colors.contrastText};
          ${Heading} {
            color: ${theme.colors.contrastText};
          }
        `;
      case CATEGORY_THEME_COLORS.GREY:
        return css`
          background-color: #9bcdc9;
          color: #e4feff;
          ${Heading} {
            color: #e4feff;
          }
        `;
      case CATEGORY_THEME_COLORS.WHITE:
        return css`
          color: ${theme.colors.text};
          ${Heading} {
            color: ${theme.colors.text};
          }
        `;
      // no-default
    }
  }};
`;

/** Emulate background-image behaviour */
const BlockBgImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

/** We need this to correctly center the content */
const BgBlockCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1; // sit above gradient
`;

const BgImageBlockWrapper = styled(BgBlockWrapper)`
  ${({ theme }) => theme.mediaQueries.smMax} {
    aspect-ratio: 16 / 9;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    aspect-ratio: 5 / 4;
  }

  /* On mobile, display the image first */
  ${({ theme }) => theme.mediaQueries.mdMax} {
    order: -1;
  }

  /* Semi-transparent gradient to aid legibility */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.4)
    );
  }
`;

const BgBlockCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: ${({ theme }) => `72px ${theme.spacing.doubleSpacing}`};
  text-align: center;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: ${({ theme }) => theme.colors.contrastText};
    text-shadow: ${({ theme }) => theme.textShadows.default};
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 320px;
    padding: ${({ theme }) => `120px ${theme.spacing.doubleSpacing}`};
  }

  ${({ theme }) => theme.mediaQueries.md} {
    min-height: 400px;
  }
`;

const BgTextBlockCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.baseSpacing};
  align-items: center;
  padding: ${({ theme }) => theme.spacing.doubleSpacing};

  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 360px;
    padding: ${({ theme }) =>
      `${theme.BASE_SPACING * 4}px ${theme.spacing.doubleSpacing}`};
  }
`;

const PopUpBlock = ({ data }) => {
  if (data.contentType === 'buttonLinkBlock') {
    return <ButtonBlock data={data} key={data.key} />;
  }

  return <ModalBlock data={data} key={data.key} />;
};

// Now a misnomer, as we use an `img` to allow us to lazy load
const BgImageBlock = ({ data: { content } }) => {
  const { backgroundImage, link, text, title, button } = content;
  const src = backgroundImage?.src;
  const imageButton = button?.length ? button[0] : null;

  return (
    <BgImageBlockWrapper>
      {src && (
        <BlockBgImage
          src={src}
          alt=""
          loading={backgroundImage?.lazyLoad ? 'lazy' : 'eager'}
        />
      )}
      <BgBlockCardWrapper>
        <BgBlockCard>
          {imageButton && <PopUpBlock data={imageButton} />}
          <Heading as="h2">{title}</Heading>
          <Text>{text}</Text>
          {link && (
            <Button as="a" href={link.url}>
              {link.name}
            </Button>
          )}
        </BgBlockCard>
      </BgBlockCardWrapper>
    </BgImageBlockWrapper>
  );
};

const BgTextBlock = ({ data: { content, settings } }) => {
  const { colors } = useTheme();
  const { blocks } = content;

  let bgColor;
  let textColor;
  let theme;

  // If we are using a theme color, match the name with the active theme
  if (settings?.backgroundColour?.contentType === CONTENT_TYPES.THEME_COLOR) {
    const { name, type } = settings.backgroundColour;

    // Is this a specific color `type`, or top-level ("general")?
    const isGeneralColor = type === THEME_COLOR_TYPES.GENERAL;
    const colorObject = isGeneralColor ? colors : colors[type];
    const isPaletteColor = Object.values(THEME_COLOR_PALETTE_TYPES).includes(
      type
    );
    const isLightColor = name.toLowerCase().startsWith('light');
    const isDarkColor = name.toLowerCase().startsWith('dark');
    const isTextColor = name.toLowerCase().includes('text');

    if (isPaletteColor) {
      const specificContrastText = isLightColor
        ? colorObject.contrastTextLight
        : colorObject.contrastText;

      textColor = specificContrastText;
    } else {
      // If we're not on top of a dark color, don't specify a textColor
      textColor = isDarkColor ? colors.contrastText : null;
    }

    if (colorObject && !isTextColor) {
      bgColor = colorObject[name];
    }
  }

  if (
    settings?.backgroundColour?.contentType ===
    CONTENT_TYPES.CATEGORY_THEME_COLOR
  ) {
    theme = settings.backgroundColour?.value;
  }

  return (
    <BgBlockWrapper
      bgColor={bgColor}
      textColor={textColor}
      categoryTheme={theme}
    >
      <BgBlockCardWrapper>
        <BgTextBlockCard>
          <Blocks data={blocks} blockLevel={1} />
        </BgTextBlockCard>
      </BgBlockCardWrapper>
    </BgBlockWrapper>
  );
};

const renderSpecificBlock = (block) => {
  const { contentType, key } = block;

  switch (contentType) {
    case BLOCK_TYPES.SPLIT_BACKGROUND_IMAGE_CONTENT:
      return <BgImageBlock data={block} key={key} />;

    case BLOCK_TYPES.SPLIT_BACKGROUND_TEXT_CONTENT:
      return <BgTextBlock data={block} key={key} />;

    default:
      return process.env.NODE_ENV === 'development' ? (
        <span>Unknown block type `{contentType}`</span>
      ) : null;
  }
};

const SplitBgBlock = ({ data }) => (
  <Wrapper>
    {data.content.blocks?.map((block) => renderSpecificBlock(block))}
  </Wrapper>
);

export { SplitBgBlock };
