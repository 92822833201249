import styled from 'styled-components';
import {
  Button,
  BUTTON_VARIANTS,
  Card,
  CARD_VARIANTS,
  Grid,
  Heading,
} from '@puregym/ui';
import { ImageBlock } from '~/blocks';
import { Icon } from '~/ui';

const StyledGrid = styled(Grid)`
  ${({ theme }) => theme.mediaQueries.lgMax} {
    gap: ${({ theme }) => theme.spacing.baseSpacing};
  }
`;

const StyledCard = styled(Card)`
  width: 100%;
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;

  ${Heading}, a:not(${Button}) {
    color: ${({ theme }) => theme.colors.text};
  }

  &:focus-visible {
    box-shadow: ${({ theme }) => theme.boxShadows.focus};
  }
`;

const ImageWrapper = styled.div`
  overflow: hidden;
  margin: ${({ theme }) =>
    `-${theme.spacing.baseSpacing} -${theme.spacing.baseSpacing} 0`};
  img {
    border-radius: ${({ theme }) =>
      ` ${theme.radii.default} ${theme.radii.default} 0 0`};
  }
`;

const ReadMoreBtn = styled(Button)`
  margin-top: auto;
  padding: 0;
  color: ${({ theme }) => theme.colors.primary.main};

  span {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

const RelatedPostBlock = ({
  data: { excerpt, name, url, primaryPostImage },
  linkText,
}) => (
  <StyledCard variant={CARD_VARIANTS.PLAIN} as={url ? 'a' : 'div'} href={url}>
    {primaryPostImage && (
      <ImageWrapper>
        <ImageBlock
          data={{
            content: primaryPostImage,
            settings: {
              lazyLoad: true,
            },
          }}
        />
      </ImageWrapper>
    )}
    <b>{name}</b>
    {excerpt}
    <ReadMoreBtn variant={BUTTON_VARIANTS.PLAIN} tabIndex="-1">
      {linkText}
      <span>
        <Icon name="arrow-right" aria-hidden={true} />
      </span>
    </ReadMoreBtn>
  </StyledCard>
);

const BlogPostRelatedPostsBlock = ({ data: { content } }) => {
  const blogPostInfo = content?.blogPostInfo;
  const heading = content?.heading;
  const linkText = content?.linkText;

  if (!blogPostInfo) return null;

  return (
    <>
      <Heading>{heading}</Heading>
      <StyledGrid minItemWidth="240px">
        {blogPostInfo.map((blogPost) => (
          <RelatedPostBlock
            data={blogPost}
            key={blogPost.key}
            linkText={linkText}
          />
        ))}
      </StyledGrid>
    </>
  );
};

export { BlogPostRelatedPostsBlock };
